import * as Yup from "yup";

import { Formik, Form as FormikForm } from "formik";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import React, { useState } from "react";

import AuthController from "@controllers/AuthController";
import Button from "@components/Button";
import FormCaption from "@components/FormCaption";
import FormDescription from "@components/FormDescription";
import FormError from "@components/FormError";
import FormHeader from "@components/FormHeader";
import FormLinks from "@components/FormLinks";
import FormView from "@components/FormView";
import TextField from "@components/TextField";
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import { useIubenda } from "@hooks/useIubenda";
import { clearFranlyLocalStorage } from "@utils/utils";

const initialValues = {
  password: "",
  passwordConfirmation: "",
};

interface IResetPasswordForm {
  password: string;
  passwordConfirmation: string;
}

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must contain at least 8 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain letters, uppercase, numbers and special character",
    ),
  passwordConfirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords doesn't match"),
});

const ResetPassword = () => {
  useIubenda();

  const { token } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
  const [errorNotification, setErrorNotification] = useState<boolean>(false);

  const email = searchParams.get("email");
  const firebase = searchParams.get("firebase");
  const welcome = _.isEqual(location.pathname, `/create-password/${token}`);

  const validateFormHasEmptyFields = (values: IResetPasswordForm) => {
    const { passwordConfirmation, password } = values;
    if (!_.isEmpty(passwordConfirmation) && !_.isEmpty(password)) return false;
    return true;
  };

  const onSuccess = () => {
    clearFranlyLocalStorage();
    setIsLoading(false);
    setIsSuccess(true);
  };

  const onError = (error: any) => {
    setIsLoading(false);
    clearFranlyLocalStorage();
    if (error?.response?.status === 403) {
      setIsTokenExpired(true);
    } else {
      setErrorNotification(true);
    }
  };

  const onSubmit = (values: IResetPasswordForm) => {
    setIsLoading(true);
    setErrorNotification(false);
    const params = {
      token: token || "",
      email: email || "",
      firebase: firebase || "",
      password: values.password,
      password_confirmation: values.passwordConfirmation,
    };
    if (token && email)
      AuthController.resetPassword(onSuccess, onError, params);
    else setIsTokenExpired(true);
  };

  return (
    <FormView
      renderHeader={
        <FormHeader
          title=""
          subtitle={welcome ? "Create Password" : "Reset Password"}
        />
      }
      renderFooter={<FormLinks hideLink />}
    >
      {isTokenExpired ? (
        <div className="p-4">
          <FormCaption
            content={
              <p>
                This link has expired. <br />
                If you still need to reset your password <br />
                please <Link to="/forgot-password">click here</Link>.
              </p>
            }
          />
        </div>
      ) : isSuccess ? (
        <div className="d-flex flex-grow-1 flex-column">
          <div className="p-4">
            {welcome ? (
              <FormCaption
                content={
                  <p>
                    Your password has been created successfully! You are ready
                    to log in.
                  </p>
                }
              />
            ) : (
              <FormCaption
                content={
                  <p>
                    Your password has been reset successfully! You are ready to
                    log in.
                  </p>
                }
              />
            )}
          </div>
          <Link
            to="/"
            className="bottom-button-container text-decoration-none d-flex flex-grow-1 flex-column justify-content-end align-items-center"
          >
            <Button type="submit" size="big" typeStyle="block" value="Login" />
          </Link>
        </div>
      ) : (
        <>
          <FormDescription text="Remember password must contain: uppercase, lowercase, number, symbol and 8 characters at least." />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ResetPasswordSchema}
          >
            {({ errors, handleBlur, values }) => (
              <FormikForm
                className="c-login__form-container d-flex flex-column flex-grow-1"
                noValidate
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <TextField
                  name="password"
                  label="New Password"
                  error={errors.password}
                  type="password"
                  placeholder="New Password"
                  handleBlur={handleBlur}
                  errorHandler={errorNotification}
                  renderError={
                    errorNotification ? (
                      <FormError error="Invalid Credentials" />
                    ) : (
                      <></>
                    )
                  }
                  iconName="lock"
                />
                <TextField
                  name="passwordConfirmation"
                  label="Password"
                  error={errors.passwordConfirmation}
                  type="password"
                  placeholder="Confirm Password"
                  handleBlur={handleBlur}
                  errorHandler={errorNotification}
                  renderError={
                    errorNotification ? (
                      <FormError error="Invalid Credentials" />
                    ) : (
                      <></>
                    )
                  }
                  iconName="lock"
                />
                <div className="bottom-button-container d-flex flex-grow-1 justify-content-center align-items-end">
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    size="big"
                    typeStyle="block"
                    value={welcome ? "Create" : "Reset"}
                    disabled={
                      validateFormHasEmptyFields(values) || !_.isEmpty(errors)
                    }
                  />
                </div>
              </FormikForm>
            )}
          </Formik>
        </>
      )}
    </FormView>
  );
};

export default ResetPassword;
