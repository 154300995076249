import DocumentsListSection from "../../sections/Documents/DocumentsListSection";
import PinUnpinProvider from "../../providers/PinUnpinProvider";
import React, { useEffect } from "react";
import { useGetDocument } from "@redux/documents/api";
import { useParams, useNavigate } from "react-router-dom";
import useAppDispatch from "@hooks/useAppDispatch";
import { restartSelectedFiles } from "@redux/files/slices/fileSlice";

const DocumentsList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: document } = useGetDocument({
    id: Number(id),
  });

  useEffect(() => {
    if (document && document.data.type) {
      navigate(`/document/${document.data.id}`, { replace: true });
    }
    dispatch(restartSelectedFiles());
  }, [document]);

  return (
    <PinUnpinProvider>
      <DocumentsListSection />
    </PinUnpinProvider>
  );
};

export default DocumentsList;
