import "react-activity/dist/library.css";

import { IFile, IFolder } from "@models/files/File.type";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import {
  applyFileOrderFilters,
  getResultsWithQuery,
} from "@redux/results/thunks/adminResultThunk";
import {
  createFolderFromDocumentsV2,
  getDocuments,
  showDocument,
  updateDocument,
} from "@redux/documents/thunks/documentsThunk";
import {
  isAlreadyASection,
  isSectionCantDelete,
} from "@helpers/warningMessages";
import {
  removeChildren,
  setFileOrderFilters,
} from "@redux/results/slices/adminResultSlice";

import ActionModal from "@components/ActionModal";
import ActionsColumn from "@components/ActionsColumn";
import AddFolderModal from "@components/AddFolderModal";
import AdminHeader from "@components/AdminHeader";
import Button from "@components/Button";
import { DeleteFileModal } from "@components/modals/DeleteFileModal";
import { DeleteFilePermanentlyModal } from "@components/modals/DeleteFilePermanentlyModal";
import DocumentsSection from "../filesManagement/DocumentsSection";
import FileName from "@components/FileName";
import { GenericModal } from "@components/Modal";
import Grid from "@components/Grid";
import { IRow } from "@models/Table.type";
import { RenameModal } from "@components/modals/RenameModal";
import { RestoreModal } from "@components/modals/RestoreModal";
import SimpleSearch from "@components/Search/SimpleSearch";
import { Spinner } from "react-activity";
import { TIcon } from "@components/MaterialIcon";
// import { TTag } from "@models/Tag.type";
import Table from "@components/Table";
import { TagModal } from "@components/modals/TagModal";
// import { TagsPopUp } from "@components/TagsPopUp";
import _ from "lodash";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import { downloadFiles } from "@helpers/downloadFiles";
import { formatDate } from "@helpers/formatDate";
import { setCurrentResource } from "@redux/documents/documentsSlice";
import { setElementToTag } from "@redux/files/slices/fileSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import useDocumentsContext from "@hooks/useDocumentsContext";
import { useNavigate } from "react-router-dom";
import { useRenameElementActive } from "@hooks/useRenameElementActive";
import useToastContext from "@hooks/useToastContext";
import useWindowSize from "@hooks/useWindowSize";
import { useAddSectionDocument } from "@redux/sections/api";
import { useDownloadOriginalFiles } from "@hooks/useDownloadOriginalFiles";

interface IAdminResultsProps {}

const tagIcon: TIcon = "tag";
const addIcon: TIcon = "add";
const deleteIcon: TIcon = "delete";
const historyIcon: TIcon = "history";
const fileDownloadIcon: TIcon = "file_download";
const driveFileRenameIcon: TIcon = "drive_file_rename_outline";
const moveToIcon: TIcon = "drive_file_move";

const AdminResults: React.FC<IAdminResultsProps> = () => {
  const [addSectionDocument] = useAddSectionDocument();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { responsive } = useWindowSize();
  const { downloadSingleFile } = useDownloadOriginalFiles();
  const [selectedFile, setSelectedFile] = useState<IFile>();
  const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [isOpenMoveToModal, setIsOpenMoveToModal] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isModalAddFolderOpen, setIsModalAddFolderOpen] = useState(false);
  const [restoreModalVisible, setRestoreModalVisible] =
    useState<boolean>(false);
  const {
    elementToMove,
    setElementToMove,
    restartDocuments,
    searchDocument,
    setSearchDocument,
  } = useDocumentsContext();

  const { showToast } = useToastContext();

  const {
    file: { data: fileData },
  } = useAppSelector((state) => state);
  const { data: user } = useAppSelector((state) => state.user);

  const {
    documents: {
      current: currentResource,
      pending: pendingDocument,
      pendingUpdating,
    },
  } = useAppSelector((state) => state);

  const renameElementActive = useRenameElementActive();
  const { setElement: setRenameElementId, elementToRename } =
    renameElementActive;

  const {
    query,
    data: adminResults,
    pending,
    orderFilter,
    orderFilterName,
  } = useAppSelector((state) => state.adminResults);
  const {
    toggle: { views },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (query) {
      dispatch(getResultsWithQuery(query));
      dispatch(setFileOrderFilters({ orderingId: 1, label: "Newest" }));
    } else navigate(`/admin/file-management`, { replace: true });
  }, [query]);

  const handleDownload = (fileId: string) => {
    downloadSingleFile(fileId);
  };

  const actionList = [
    {
      id: "download",
      icon: fileDownloadIcon,
      text: "Download",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        const isFolder = file?.file_id === null;

        if (isFolder) {
          downloadFiles([file], dispatch);
        } else {
          handleDownload(`${selectId}`);
        }
      },
    },
    {
      id: "moveTo",
      icon: moveToIcon,
      text: "Move to",
      onClick: (element: IFile) => {
        setIsOpenMoveToModal(true);
        setElementToMove({
          id: element.id,
          name: element.name,
          parent_id: element.parent_id,
          type: element.type,
          document_tags: element.document_tags,
        });
        dispatch(
          setCurrentResource({
            data: {
              id: fileData.id,
              name: fileData.name,
              parent_id: fileData.parent_id,
            },
          }),
        );
      },
      hidden: !responsive.md || !user?.permissions.Document?.includes("write"),
    },
    {
      id: "delete",
      icon: deleteIcon,
      text: "Delete",
      onClick: (element: IFile) => {
        if (element.section) {
          isSectionCantDelete(dispatch);
        } else {
          setSelectedFile(element);
          setModalDeleteVisible(true);
        }
      },
    },
    {
      id: "rename",
      icon: driveFileRenameIcon,
      text: "Rename",
      onClick: (file: IFile) => {
        setRenameElementId(file);

        if (!responsive.md) {
          setShowRenameModal(true);
        }
      },
    },
    {
      id: "tags",
      icon: tagIcon,
      text: "Tags",
      onClick: (file: IFile) => {
        setShowTagsModal(true);
        dispatch(setElementToTag(file));
      },
    },
    {
      id: "addSection",
      icon: addIcon,
      text: "Add as Section",
      onClick: (folder: IFolder) =>
        addSectionDocument({ sectionableId: folder.id })
          .unwrap()
          .then(() => {
            const text = `<strong>New Section added</strong><br>"${folder.name}" has been added as section successfully.<br/>
      <a style="color: white" href="${process.env.REACT_APP_BACK_BASE_URL}admin/new-section" target="_blank">Find it here</a>`;
            createToast(text, "success", dispatch);
          })
          .catch(() => {
            isAlreadyASection(folder, dispatch);
          }),
    },
    {
      id: "history",
      icon: historyIcon,
      text: "History",
      onClick: (file: IFile) => {
        navigate(`/admin/file-management/history/${file.id}`, {
          replace: true,
        });
        localStorage.setItem("current_file_history", JSON.stringify(file));
      },
    },
  ];

  const columns = [
    {
      header: "Name",
      id: "name",
      colspan: { xs: "3" },
      field: "name",
      body: (file: IRow) => {
        return (
          <FileName
            name={file.name}
            type={file.type}
            deleted_at={file.deleted_at}
            rename={_.isEqual(file.id, elementToRename?.id)}
          />
        );
      },
    },
    {
      header: "Date",
      id: "created_at",
      colspan: { xs: "1" },
      field: "created_at",
      parsedString: ({ created_at }: IRow) => formatDate(created_at),
      fixedWidth: { xs: "100px", sm: "unset" },
    },
    {
      header: "Type",
      id: "type",
      colspan: { xs: "1" },
      field: "type",
      parsedString: ({ type }: IRow) => (type ? `.${type}` : "folder"),
      collapsable: true,
    },
    {
      header: "Tags",
      id: "tags",
      colspan: { xs: "1" },
      field: "",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      body: (file: IRow) => {
        // const tags =
        //   tagsByDocuments[file.id]?.map((o: TTag) => o.name).join(", ") || "-";
        // return !_.isEqual(tags, "-") ? (
        //   <TagsPopUp list={tagsByDocuments} tags={tags} documentId={file.id} />
        // ) : (
        //   <span className="o-ft-sm-400 o-cl-grey-200">{tags}</span>
        // );
        return <span />;
      },
      collapsable: true,
    },
    {
      header: "Actions",
      id: "actions",
      colspan: { xs: "1" },
      field: "",
      body: (file: IRow) => (
        <ActionsColumn
          {...{ file }}
          actionList={actionList}
          dotsShowLength={4}
        />
      ),
      hiddenMobileTitle: true,
      fixedWidth: { xs: "40px", lg: "212px" },
    },
  ];

  const orderingFunction = ({
    orderingId,
    label,
  }: {
    orderingId: string;
    label: string;
  }) => {
    dispatch(applyFileOrderFilters({ orderingId, label, query }));
  };

  const renderData = () =>
    views.admin.grid ? (
      <Grid
        {...{ actionList }}
        draggable={false}
        data={adminResults ? [...adminResults] : []}
        dynamicCardClassName={(args: IRow) => {
          const { id } = args;
          let className = "o-bg-transparent";
          if (responsive.md) {
            className = "c-grid__child:hover o-bg-grey-300:hover";
            if (_.isEqual(id, elementToRename?.id)) {
              className = "c-grid__child--selected o-bg-grey-300";
            }
          }
          return className;
        }}
        layout="c-grid--xl-layout"
      />
    ) : (
      <Table
        data={adminResults ? [...adminResults] : []}
        dynamicRowClassName={(args: IRow) => {
          const { id } = args;
          let className = "o-bg-transparent";
          if (responsive.md) {
            className = "o-bg-grey-300:hover";
            if (_.isEqual(id, elementToRename?.id)) {
              className = "o-bg-grey-300 c-table__row--selected";
            }
          }
          return className;
        }}
        {...{ columns }}
        extraClassName={`${responsive.md && "c-table--selectable mt-md-3"}`}
      />
    );

  const handleOnType = (value: string) => {
    setSearchDocument({
      query: value,
      only_folders: searchDocument !== "",
      parentId: currentResource?.id ? currentResource?.id : undefined,
    });
  };

  const handleOnClear = () => {
    setSearchDocument({
      query: "",
      only_folders: false,
      parentId: currentResource?.id ? currentResource?.id : undefined,
    });
  };

  const onArrowButtonClick = () => {
    dispatch(
      getDocuments({ parentId: currentResource?.parent_id || undefined }),
    );
    if (!currentResource?.parent_id) {
      dispatch(
        setCurrentResource({
          data: {
            parent_id: undefined,
            id: null,
            name: null,
          },
        }),
      );
    } else {
      dispatch(showDocument({ id: currentResource?.parent_id || undefined }));
    }
  };

  const moveElement = () => {
    if (elementToMove) {
      dispatch(
        updateDocument({
          id: elementToMove.id,
          params: {
            parent_id: currentResource?.id || null,
            action: "keep_both",
            name: elementToMove.name,
          },
        }),
      ).then((response) => {
        dispatch(
          setCurrentResource({
            data: {
              parent_id: undefined,
              id: null,
              name: null,
            },
          }),
        );
        if (response.meta.requestStatus === "fulfilled") {
          showToast("success", dispatch, response.meta.arg.params.parent_id);
          dispatch(removeChildren({ id: elementToMove.id }));
          return;
        }
        if (response.meta.requestStatus === "rejected") {
          showToast("danger", dispatch);
        }
      });
    }
    setIsConfirmModalOpen(false);
    restartDocuments();
  };

  return (
    <>
      <TagModal isVisible={showTagsModal} setVisible={setShowTagsModal} />
      <DeleteFileModal
        file={selectedFile}
        isVisible={modalDeleteVisible}
        setVisible={setModalDeleteVisible}
      />
      <RestoreModal
        file={selectedFile}
        isVisible={restoreModalVisible}
        setVisible={setRestoreModalVisible}
      />
      {!responsive.md && (
        <RenameModal
          isVisible={showRenameModal}
          setVisible={setShowRenameModal}
          renameElementActive={renameElementActive}
        />
      )}
      <DeleteFilePermanentlyModal
        file={selectedFile}
        isVisible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
      />
      <AddFolderModal
        folderId={currentResource?.id || null}
        isVisible={isModalAddFolderOpen}
        setIsVisible={setIsModalAddFolderOpen}
        onConfirmFolder={(folder: any) =>
          dispatch(
            createFolderFromDocumentsV2({
              params: {
                name: folder.name,
                action: "keep_both",
                parent_id: folder.parent_id,
                document_tags: null,
              },
            }),
          ).then(() => {
            const text = `<strong>Folder Created</strong><br>A new folder has been created`;
            createToast(text, "success", dispatch);
          })
        }
      />
      <GenericModal
        title={currentResource?.name || "File Management"}
        subtitle={
          <>
            Moving element: <b>{elementToMove?.name}</b>
          </>
        }
        onConfirmButton={() => {
          setIsOpenMoveToModal(false);
          setIsConfirmModalOpen(true);
        }}
        onCancelButton={() => {
          setIsOpenMoveToModal(false);
          restartDocuments();
        }}
        show={isOpenMoveToModal}
        confirmModalText="Move"
        withCancelButton={false}
        withArrowButton={currentResource?.parent_id !== undefined}
        onArrowButtonClick={() => onArrowButtonClick()}
        disabledConfirmButton={elementToMove?.parent_id === currentResource?.id}
        search={
          <SimpleSearch
            onType={handleOnType}
            onClear={handleOnClear}
            value={searchDocument}
            placeholder="Search folder"
            isFetching={false}
            borderStyle
          />
        }
        otherActions={[
          {
            action: (
              <Button
                onClick={() => setIsModalAddFolderOpen(true)}
                className="ms-md-1"
                icon={{
                  icon: "folder_open",
                  size: 18,
                  position: "right",
                }}
                value="Add Folder"
                disabled={pendingDocument}
              />
            ),
          },
        ]}
      >
        <DocumentsSection />
      </GenericModal>
      <ActionModal
        isLoading={pendingUpdating}
        show={isConfirmModalOpen}
        title={`Move ${elementToMove?.type ? "file" : "folder"}`}
        subtitle={
          <>
            You are about to move &quot;{elementToMove?.name}&quot;{" "}
            {elementToMove?.type ? "file" : "folder"} to the following location:{" "}
            <span className="fw-bold">
              {`File Management${currentResource?.path || ""}`}
            </span>
          </>
        }
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setIsConfirmModalOpen(false)}
        onClickRightButton={() => moveElement()}
      />
      <div className="o-admin-screen-container w-100">
        <div className="row">
          <div className="col-12">
            <AdminHeader
              title="Results"
              ordering={{ orderFilter, orderFilterName, orderingFunction }}
            />
            <div style={{ paddingTop: 50, paddingBottom: 100 }}>
              {pending ? (
                <div className="d-flex justify-content-center mt-5">
                  <Spinner
                    className="o-ft-xl o-ft-3xl@md"
                    color={colors.brandColorSecondary}
                  />
                </div>
              ) : (
                renderData()
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminResults;
