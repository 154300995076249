/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
import FavoritesSection from "@sections/Favorites/FavoritesSection";
import PinUnpinProvider from "../providers/PinUnpinProvider";
import React, { useEffect } from "react";
import useAppDispatch from "@hooks/useAppDispatch";
import { restartSelectedFiles } from "@redux/files/slices/fileSlice";

const Favorites = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(restartSelectedFiles());
  }, [document]);
  return (
    <PinUnpinProvider>
      <FavoritesSection />
    </PinUnpinProvider>
  );
};

export default Favorites;
