import PinUnpinProvider from "../providers/PinUnpinProvider";
import VaultsSection from "../sections/Vaults/VaultsSection";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAppDispatch from "@hooks/useAppDispatch";
import { restartSelectedFiles } from "@redux/files/slices/fileSlice";

const Vault = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(restartSelectedFiles());
  }, [document]);
  return (
    <PinUnpinProvider vaultId={Number(id)}>
      <VaultsSection />
    </PinUnpinProvider>
  );
};

export default Vault;
