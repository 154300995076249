/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import {
  useGetContacts,
  useGetContactsBySearch,
  useGetContactTagCategories,
} from "@redux/contacts/api";

import CardList from "@components/CardList";
import Chip from "@components/Chip";
import ContactTable from "@components/ContactTable";
import Error from "@components/Error";
import HeaderModule from "@components/HeaderModule";
import Order from "./shared/Filters/Order";
import Search from "@components/Search/SearchOld";
import { Spinner } from "react-activity";
import SwitchMode from "@components/SwitchMode";
import { colors } from "@theme/colors";
import emptyState from "../../assets/no-results.svg";
import useAppSelector from "@hooks/useAppSelector";
import useWindowSize from "@hooks/useWindowSize";
import MultiSelectWithCheckbox, {
  ChevronDown,
  OptionType,
} from "@components/Form/MultiSelectWithCheckbox";
import Select, { SingleValue, StylesConfig, GroupBase } from "react-select";
import Button from "@components/Button";
import chroma from "chroma-js";
import MaterialIcon from "@components/MaterialIcon";

const baseSrcUrl =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "";

const orderOptions = [
  { id: 1, name: "Newest" },
  { id: 2, name: "Oldest" },
  { id: 3, name: "A-Z" },
  { id: 4, name: "Z-A" },
];

const Contacts: React.FC = () => {
  const module = "contacts";
  const { responsive } = useWindowSize();

  const [searchValue, setSearchvalue] = useState("");
  const [selectedCategory, setSelectedCategory] =
    useState<SingleValue<OptionType>>(null);
  const [orderBy, setOrderBy] = useState<"created_at" | "name">("created_at");
  const [orderDirection, setOrderDirection] = useState<"desc" | "asc">("desc");
  const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
  const [displayedData, setDisplayedData] = useState<any[]>([]);
  const [allContactsData, setAllContactsData] = useState<any[]>([]);
  const [tagsSelect, setTagsSelect] = useState([]);
  const [isOpenSelectTags, setIsOpenSelectTags] = useState(false);
  const [page, setPage] = useState(1);

  const {
    data: contacts,
    isFetching,
    isLoading,
  } = useGetContacts({
    page,
    orderBy,
    orderDirection,
    tags: selectedTags.map((tag) => tag.value),
  });

  const { data: categoriesTagData } = useGetContactTagCategories();

  const categoriesAndTagSelect = useMemo(() => {
    const value = categoriesTagData?.map((data: any) => ({
      label: data.name,
      options: data.tags.map((tag: any) => ({
        value: tag.id,
        label: tag.name,
      })),
    }));
    return value;
  }, [categoriesTagData]);

  const categoriesSelect = useMemo(() => {
    const value = categoriesTagData?.map((data: any) => ({
      label: data.name,
      value: data.id,
    }));
    return value;
  }, [categoriesTagData]);

  const { data: searchData, isFetching: isLoadingSearch } =
    useGetContactsBySearch({
      tags: selectedTags.map((tag) => tag.value),
      query: searchValue,
    });

  const handleScroll = (event: any) => {
    const { target } = event;
    if (
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1 &&
      target.scrollHeight - target.scrollTop >= target.clientHeight - 1 &&
      contacts.links.next &&
      !isFetching
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (contacts) {
      setDisplayedData([...displayedData, ...contacts.data]);
      setAllContactsData([...displayedData, ...contacts.data]);
    }
  }, [contacts]);

  const handleOnType = (value: string) => {
    setSearchvalue(value);
  };

  const { views } = useAppSelector((state) => state.toggle);

  const selectOrder = (option: { id: number; name: string }) => {
    setDisplayedData([]);
    setPage(1);
    setOrderBy(option.id <= 2 ? "created_at" : "name");
    setOrderDirection([1, 4].includes(option.id) ? "desc" : "asc");
  };

  const handleSelectItem = (item: { id: number; name: string }) => {
    setDisplayedData([
      searchData.find((contact: any) => contact.id === item.id),
    ]);
    setSearchvalue(item.name);
  };

  const formatedSearchResults = useMemo(() => {
    const data = searchValue ? searchData : [];
    const mappedData =
      data.map((contact: any) => ({
        ...contact,
        icon: "account_circle",
      })) || [];
    return {
      contacts: {
        data: mappedData,
        meta: {},
        links: {},
      },
    };
  }, [searchData, searchValue]);

  useEffect(() => {
    if (categoriesAndTagSelect) {
      setTagsSelect(categoriesAndTagSelect);
    }
  }, [categoriesAndTagSelect]);

  const renderContent = () => {
    if (displayedData.length === 0 && !isFetching && !isLoading) {
      return (
        <Error
          src={baseSrcUrl + emptyState}
          title="No Results Found!"
          description="We couldn't find any matches for your search. Please try again."
        />
      );
    }
    return views[module].grid ? (
      <CardList data={displayedData} />
    ) : (
      <ContactTable data={displayedData} />
    );
  };

  const selectStyles: StylesConfig<OptionType, false, GroupBase<OptionType>> = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: isFocused ? colors.brandColorSecondary : styles.borderColor,
      boxShadow: isFocused
        ? `0 0 0 1px ${colors.brandColorSecondary}`
        : styles.boxShadow,
      "&:hover": {
        borderColor: colors.brandColorSecondary,
      },
      border: "none",
      height: "50px",
      padding: "8px 16px",
    }),
    option: (styles, { isFocused, isSelected }) => {
      const color = chroma(colors.brandColorSecondary);
      return {
        ...styles,
        backgroundColor: isSelected
          ? colors.brandColorSecondary
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
        color: isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : "black",
        cursor: "pointer",

        ":active": {
          ...styles[":active"],
          backgroundColor: isSelected
            ? colors.brandColorSecondary
            : color.alpha(0.3).css(),
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, color: colors.grey100 }),
    singleValue: (styles) => ({
      ...styles,
    }),
    container: (base) => {
      return {
        ...base,
        width: "100%",
        height: "50px",
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      padding: "0",
    }),
  };
  const filterTagsByCompany = (option: SingleValue<OptionType>) => {
    setSelectedCategory(option);
    setTagsSelect(categoriesAndTagSelect);
    setIsOpenSelectTags(false);
    if (option) {
      const values = categoriesTagData
        ?.filter((category: any) => category.id === option.value)
        .map((data: any) => ({
          label: data.name,
          options: data.tags.map((tag: any) => ({
            value: tag.id,
            label: tag.name,
          })),
        }));
      setTagsSelect(values);
      setIsOpenSelectTags(true);
    }
  };

  const handleOnClear = () => {
    setSearchvalue("");
    setSelectedCategory(null);
    setSelectedTags([]);
    setPage(1);
    setDisplayedData([]);
    setTagsSelect(categoriesAndTagSelect);
  };

  const customSingleValue = ({ data }: { data: OptionType }) => (
    <div
      style={{
        display: "flex",
        position: "absolute",
        gap: "8px",
      }}
    >
      <div
        style={{
          transform: "rotate(90deg)",
        }}
      >
        <MaterialIcon icon="sell" color="o-cl-brand-primary" />
      </div>
      <span>{data.label}</span>
    </div>
  );

  const selectedTagsChips = useMemo(() => {
    const chips = selectedTags.reduce(
      (prev, current) => {
        // Encuentra la categoría correspondiente al tag actual
        const category = categoriesAndTagSelect.find((cat: any) =>
          cat.options.some((option: any) => current.value === option.value),
        );

        if (category) {
          const categoryName = category.label;

          // Si la categoría aún no existe en el objeto, inicialízala como un array vacío
          if (!prev[categoryName]) {
            prev[categoryName] = [];
          }

          // Agrega el tag actual al array de la categoría correspondiente
          prev[categoryName].push(current);
        }

        return prev;
      },
      {} as Record<string, any[]>,
    ); // Define el tipo del objeto resultante

    return chips;
  }, [selectedTags, categoriesAndTagSelect]);

  useEffect(() => {
    if (
      selectedCategory &&
      !isOpenSelectTags &&
      selectedTagsChips[selectedCategory.label]
    ) {
      setSelectedCategory(null);
      setTagsSelect(categoriesAndTagSelect);
    }
  }, [isOpenSelectTags, selectedCategory, selectedTagsChips]);

  return (
    <div className="o-screen-container" onScroll={handleScroll}>
      <HeaderModule title="Contacts" withBreadcrumb />

      <div className="d-flex justify-content-between align-items-center mb-4 flex-column flex-md-row gap-2">
        <div
          className="d-flex align-items-center"
          style={{
            boxShadow: "6px 10px 15px -4px rgba(0,0,0,.08)",
            width: responsive.md ? "30%" : "100%",
          }}
        >
          <Search
            onType={handleOnType}
            onSelectItem={handleSelectItem}
            onClear={() => {
              setSearchvalue("");
              setDisplayedData(allContactsData);
            }}
            value={searchValue}
            results={formatedSearchResults}
            placeholder="Search by name"
            isFetching={isLoadingSearch}
            borderStyle={false}
            viewAllBtn={false}
          />
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            boxShadow: "6px 10px 15px -4px rgba(0,0,0,.08)",
            width: responsive.md ? "25%" : "100%",
          }}
        >
          <Select
            options={categoriesSelect}
            value={selectedCategory}
            isClearable
            onChange={filterTagsByCompany}
            classNamePrefix="react-select"
            placeholder="Filter by Category"
            components={{
              SingleValue: customSingleValue,
              DropdownIndicator: ChevronDown,
              IndicatorSeparator: null,
            }}
            isSearchable={false}
            styles={selectStyles}
          />
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            boxShadow: "6px 10px 15px -4px rgba(0,0,0,.08)",
            width: responsive.md ? "25%" : "100%",
          }}
        >
          <MultiSelectWithCheckbox
            options={tagsSelect}
            onSelect={(values: OptionType[]) => {
              setDisplayedData([]);
              setPage(1);
              setSelectedTags(values);
            }}
            values={selectedTags}
            isOpenSelectTags={isOpenSelectTags}
            setIsOpenSelectTags={setIsOpenSelectTags}
          />
        </div>

        <div className="d-flex flex-wrap d-md-none align-items-center gap-2 mt-4">
          {Object.keys(selectedTagsChips).map((company) => {
            return (
              <Chip
                key={company}
                name={
                  <span>
                    <b>{company}: </b>
                    {selectedTagsChips[company]
                      .map((tag) => tag.label)
                      .join(", ")}
                  </span>
                }
                onClick={() => {
                  const companyValues = selectedTagsChips[company].map(
                    (tag) => tag.value,
                  );
                  const filters = selectedTags.filter(
                    (filter) => !companyValues.includes(filter.value),
                  );
                  setSelectedTags(filters);
                }}
                size="small"
              />
            );
          })}

          {selectedTags.length > 0 && (
            <Button
              onClick={() => handleOnClear()}
              value="Clear all"
              variant="link"
              isDark
              icon={{
                icon: "delete",
                position: "right",
                size: 18,
              }}
            />
          )}
        </div>

        <div className="d-flex align-items-center align-self-end align-self-md-center mt-4 mt-md-0">
          <div className="d-flex align-items-center gap-2 justify-content-end d-md-flex">
            <div className="d-flex">
              <SwitchMode module={module}>
                <div className="mx-1 d-flex">
                  <SwitchMode.Icon mode="grid" />
                </div>
                <div className="mx-1 d-flex">
                  <SwitchMode.Icon mode="list" />
                </div>
              </SwitchMode>
            </div>
          </div>
          <Order options={orderOptions} onSelect={selectOrder} />
        </div>
      </div>

      <div className="d-none align-items-center gap-2 d-md-flex my-4 flex-wrap">
        {Object.keys(selectedTagsChips).map((company) => {
          return (
            <Chip
              key={company}
              name={
                <span>
                  <b>{company}: </b>
                  {selectedTagsChips[company]
                    .map((tag) => tag.label)
                    .join(", ")}
                </span>
              }
              onClick={() => {
                const companyValues = selectedTagsChips[company].map(
                  (tag) => tag.value,
                );
                const filters = selectedTags.filter(
                  (filter) => !companyValues.includes(filter.value),
                );
                setSelectedTags(filters);
                setDisplayedData([]);
              }}
              size="small"
            />
          );
        })}

        {selectedTags.length > 0 && (
          <Button
            onClick={() => handleOnClear()}
            value="Clear all"
            variant="link"
            isDark
            icon={{
              icon: "delete",
              position: "right",
              size: 18,
            }}
          />
        )}
      </div>
      <div>{renderContent()}</div>
      {(isFetching || isLoading) && (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      )}
    </div>
  );
};

export default Contacts;
