/* eslint-disable no-plusplus */
import React from "react";
import { deleteLoggedUser } from "@redux/users/thunks/userThunk";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "@hooks/useAppDispatch";
import useWindowSize from "@hooks/useWindowSize";
import MaterialIcon from "./MaterialIcon";
import { clearFranlyLocalStorage } from "@utils/utils";

const LogOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { responsive } = useWindowSize();

  const clearData = async () => {
    clearFranlyLocalStorage();
    dispatch(deleteLoggedUser());
    const newUrl = `${process.env.REACT_APP_BACK_BASE_URL}admin/logout?origin=platform`;
    // eslint-disable-next-line no-restricted-globals
    location.href = newUrl;
  };

  const onClickInternalLogin = () => {
    clearData();
    navigate("/login", { replace: true });
  };

  return (
    <div
      className="o-cursor-pointer"
      onClick={onClickInternalLogin}
      aria-hidden
    >
      {responsive.md ? (
        <MaterialIcon icon="logout" size={24} color="o-cl-grey-200" />
      ) : (
        <div className="d-flex align-items-center">
          <MaterialIcon
            icon="manage_accounts"
            color="o-cl-grey-100"
            size={20}
            className="px-2"
          />
          <span className="c-profile-modal__link o-cl-grey-100">Log Out</span>
        </div>
      )}
    </div>
  );
};

export default LogOut;
