/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, useEffect, useState } from "react";
import Select, {
  StylesConfig,
  GroupBase,
  MultiValue,
  OptionProps,
  components,
} from "react-select";
import { colors } from "@theme/colors";
import { CustomCheck } from "./CheckBox";
import MaterialIcon from "@components/MaterialIcon";

export type OptionType = {
  value: string;
  label: string;
};

type GroupedOption = {
  label: string;
  options: OptionType[];
};

const selectStyles: StylesConfig<OptionType, true, GroupBase<OptionType>> = {
  control: (provided) => {
    return {
      ...provided,
      minWidth: 240,
      margin: 8,
      display: "flex",
      flexDirection: "row-reverse",
      borderColor: colors.brandColorPrimary,
      boxShadow: `0 0 0 1px ${colors.brandColorPrimary}`,
      ":hover": {
        borderColor: colors.brandColorPrimary,
      },
    };
  },
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "white"
        : isFocused
          ? colors.brandColorPrimary
          : undefined,
      color: isSelected ? undefined : isFocused ? "white" : undefined,
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected
          ? colors.brandColorPrimary
          : colors.brandColorPrimary,
        color: "white",
      },
    };
  },
  noOptionsMessage: (provided) => ({
    ...provided,
    color: colors.grey100,
    fontWeight: 600,
    fontSize: "12px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginLeft: "8px",
    marginRight: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    order: 1,
  }),
};

const Svg = (p: JSX.IntrinsicElements["svg"]) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);

const DropdownIndicator = () => (
  <div style={{ height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

export const ChevronDown = () => (
  <Svg style={{ marginLeft: 8 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill={colors.brandColorPrimary}
      fillRule="evenodd"
    />
  </Svg>
);

// styled components
const Menu = (props: JSX.IntrinsicElements["div"]) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};

const Blanket = (props: JSX.IntrinsicElements["div"]) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);

const CustomClearIndicator = (props: any) => {
  const { clearText } = props;

  return (
    <div
      style={{
        padding: "0 8px",
        cursor: "pointer",
        order: 0,
      }}
    >
      <MaterialIcon
        icon="close"
        color="o-cl-grey-200"
        onClick={clearText}
        className="c-search__search-icon-container d-flex o-cursor-pointer"
        size={18}
      />
    </div>
  );
};

export const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: "relative", width: "100%" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

// Componente para agregar un checkbox a cada opción
const CustomOption = (props: OptionProps<OptionType, true>) => {
  const { isSelected, label } = props;
  return (
    <components.Option {...props}>
      <CustomCheck
        name="select_option"
        label={label}
        checked={isSelected}
        size="sm"
        color={colors.grey100}
      />
    </components.Option>
  );
};

export default ({
  options,
  onSelect,
  values,
  isOpenSelectTags = false,
  setIsOpenSelectTags,
}: {
  options: GroupedOption[];
  onSelect: any;
  values: OptionType[];
  isOpenSelectTags?: boolean;
  setIsOpenSelectTags?: (isOpen: boolean) => void;
}) => {
  const [isOpen, setIsOpen] = useState(isOpenSelectTags);
  const [value, setValue] = useState<MultiValue<OptionType>>([]);

  const [searchInput, setSearchInput] = useState("");

  const handleInputChange = (inputValue: string) => {
    setSearchInput(inputValue);
  };
  const handleChange = (newValue: MultiValue<OptionType>) => {
    setValue(newValue);
    onSelect([...newValue]);
  };

  useEffect(() => {
    setValue(values);
  }, [values]);

  useEffect(() => {
    setIsOpen(isOpenSelectTags);
  }, [isOpenSelectTags]);

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setIsOpenSelectTags?.(false);
      }}
      target={
        <button
          type="button"
          onClick={() => {
            setIsOpen((prev) => !prev);
            setIsOpenSelectTags?.(false);
          }}
          style={{
            backgroundColor: "white",
            border: "none",
            borderRadius: "4px",
            padding: "8px 16px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "50px",
            color: colors.grey100,
          }}
        >
          <div className="d-flex justify-content-between w-100">
            <div>Select Tags</div>
            <div>
              <ChevronDown />
            </div>
          </div>
        </button>
      }
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{
          Option: CustomOption,
          DropdownIndicator,
          IndicatorSeparator: null,
          IndicatorsContainer: (props) => (
            <>
              {/* Lupa */}
              <div style={{ order: 1 }}>
                <components.IndicatorsContainer {...props} />
              </div>

              {/* Cruz para limpiar */}
              {searchInput && (
                <CustomClearIndicator clearText={() => setSearchInput("")} />
              )}
            </>
          ),
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        onInputChange={handleInputChange}
        isClearable={false}
        isMulti
        menuIsOpen
        onChange={handleChange}
        options={options}
        placeholder="Search"
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
        noOptionsMessage={() => `No search results for "${searchInput}"`}
      />
    </Dropdown>
  );
};
