/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useShowDocument } from "./fetchs/useShowDocument";
import JSZip from "jszip";

export interface IDownloadableFile {
  id: string | number;
  size: number;
}

export const useDownloadOriginalFiles = () => {
  const { getData } = useShowDocument();

  const downloadFileWithUrl = (url: string, name: string) => {
    return new Promise((resolve) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        resolve("");
      }, 1000);
    });
  };
  const downloadSingleFile = async (fileId: string | number) => {
    const e = await getData(fileId);
    const { download_url: downloadUrl } = e.data.file;

    const { name, type } = e.data;

    await downloadFileWithUrl(downloadUrl, `${name}.${type}`);
  };

  const fetchFileContent = async (url: string): Promise<Blob> => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error al descargar el archivo: ${response.statusText}`);
    }
    return response.blob();
  };

  const compressToZip = async (
    fileName: string,
    fileContent: Blob,
  ): Promise<Blob> => {
    const zip = new JSZip();
    zip.file(fileName, fileContent);
    const zipBlob = await zip.generateAsync({ type: "blob" });
    return zipBlob;
  };

  const downloadBlob = (blob: Blob, fileName: string) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadFile = async (fileId: string | number) => {
    const e = await getData(fileId);
    const { url } = e.data.file;

    const { name, type } = e.data;

    // Descarga el archivo desde la URL
    const fileContent = await fetchFileContent(url);

    // Comprime el archivo en un ZIP
    const zipBlob = await compressToZip(`${name}.${type}`, fileContent);

    // Descarga el ZIP
    downloadBlob(zipBlob, `${name}.zip`);
  };

  const downloadMultipleOriginalFiles = async (
    files: Array<IDownloadableFile>,
    updateEstimatedTime: (time: number) => void,
    setDownloadAllFiles: (downloadFiles: boolean) => void,
  ) => {
    setDownloadAllFiles(false);
    const totalSize = files.reduce((acc, { size }) => acc + size, 0); // Tamaño total en bytes

    // Usar la velocidad de conexión inicial o un valor estimado si no está disponible
    const initialSpeed = navigator.connection?.downlink || 5; // 5 MiB como valor por defecto
    const initialSpeedBps = initialSpeed * 1024 * 1024; // Convertir a Bps (Bytes por segundo)

    // Estimación de tiempo basado en la velocidad inicial
    const estimatedTimeSeconds = totalSize / initialSpeedBps; // Tiempo estimado en segundos

    updateEstimatedTime(Math.ceil(estimatedTimeSeconds) + 60);

    // Realizar la descarga de los archivos
    const zip = new JSZip();
    const filePromises: Array<Promise<{ name: string; content: Blob }>> = [];

    // Descargar todos los archivos
    for (const file of files) {
      const e = await getData(file.id);
      const { url } = e.data.file;
      const { name, type } = e.data;

      // Envolvemos la obtención del contenido del archivo en una promesa
      const fileContentPromise = fetchFileContent(url).then((fileContent) => ({
        name: `${name}.${type}`,
        content: fileContent,
      }));

      filePromises.push(fileContentPromise);
    }

    // Crear el archivo zip y descargarlo
    const fileContents = await Promise.all(filePromises);
    fileContents.forEach(({ name, content }) => {
      zip.file(name, content);
    });

    // Generar el archivo ZIP
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const today = new Date();

    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    downloadBlob(
      zipBlob,
      `${process.env.REACT_APP_BRAND_NAME}_files_${year}${month}${day}.zip`,
    );

    updateEstimatedTime(0); // Finalizar
    setDownloadAllFiles(true);
  };

  return {
    downloadMultipleOriginalFiles,
    downloadFile,
    downloadSingleFile,
  };
};
