/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGetDocument, useGetDocuments } from "@redux/documents/api";

import Grid from "@components/Grid";
import Header from "@views/Section/shared/Header";
import { IAction } from "@models/Action.type";
import { IFile } from "@models/files/File.type";
import PaginationLoader from "@components/PaginationLoader";
import PinModal from "@components/PinModal";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-activity";
import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import { trackInteractions } from "@redux/files/thunks/fileThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import { useHandleFavorite } from "@hooks/useHandleFavorite";
import { useParams } from "react-router-dom";
import { usePinUnpinContext } from "../../providers/PinUnpinProvider";
import useBreadcrumb from "@hooks/useBreadcrumb";
import { usePinActionList } from "@hooks/usePinActionList";
import { useDownloadOriginalFiles } from "@hooks/useDownloadOriginalFiles";
import useAppSelector from "@hooks/useAppSelector";
import useWindowSize from "@hooks/useWindowSize";
import { isEqual } from "lodash";
import { IRow } from "@models/Table.type";
import Button from "@components/Button";
import {
  addToast,
  removeToast,
  updateToast,
} from "@redux/toasts/slices/toastsSlice";
import { EToastTypes, IToast } from "@models/toast/Toast.type";
import { restartSelectedFiles } from "@redux/files/slices/fileSlice";

const DocumentsListSection: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { responsive } = useWindowSize();
  const dispatch = useAppDispatch();
  const { downloadMultipleOriginalFiles, downloadSingleFile } =
    useDownloadOriginalFiles();
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null); // Tiempo en segundos
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  const [downloadAllFiles, setDownloadAllFiles] = useState<boolean | null>(
    null,
  );
  const isDownloading = downloadAllFiles !== null && !downloadAllFiles;
  const { data: documentSection, isFetching: isFetchingDocument } =
    useGetDocument({
      id: Number(id),
    });
  const { data: documents, isFetching } = useGetDocuments({
    parentId: Number(id),
  });

  const [documentsData, setDocumentsData] = useState<any>(null);

  const { breadcrumb, generatingBreadcrumb } = useBreadcrumb(Number(id));
  const {
    isOpenPinModal,
    setIsOpenPinModal,
    tooglePinDashboard,
    tooglePinBottomBar,
  } = usePinUnpinContext();

  const { actionList: pinActionList } = usePinActionList({
    document: documentSection?.data,
    endpoint: "getDocument",
    tooglePinDashboard,
    tooglePinBottomBar,
  });
  const { handleFavorite } = useHandleFavorite();
  let timer: NodeJS.Timeout;
  const {
    file: { selectedFiles },
  } = useAppSelector((state) => state);

  const handleDownload = (fileId: string) => {
    downloadSingleFile(fileId);
  };

  const actionList: IAction[] = [
    {
      id: "favorite",
      icon: "favorite" as TIcon,
      text: "",
      type: "favorite",
      onClick: (file: IFile) => {
        handleFavorite(file);
      },
    },
    {
      id: "copy-link",
      icon: "file_copy",
      text: "Copy Link",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        if (selectId) {
          navigator.clipboard.writeText(
            `${window.location.origin}/document/${selectId}`,
          );
          createToast("Copied", "success", dispatch);
        }
        dispatch(trackInteractions({ data: [file.id], type: "share" }));
      },
    },
    {
      id: "download",
      icon: "file_download",
      text: "Download",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        handleDownload(`${selectId}`);
      },
    },
  ];

  const handleDownloadMultipleFiles = () => {
    const downloadToast: IToast = {
      id: "download_document_list",
      text: `<div class="d-flex">
            <div class="loader"></div>
            <span> Preparing for download. Please do not leave this page.</span>
          </div>`,
      type: EToastTypes.WARNING,
      withClose: true,
      autohide: false,
    };
    dispatch(addToast(downloadToast));
    downloadMultipleOriginalFiles(
      selectedFiles.map((file) => {
        return {
          id: file.id,
          size: file.size,
        };
      }),
      (time) => {
        setEstimatedTime(time); // Establecer tiempo estimado total
      },
      setDownloadAllFiles,
    );
  };

  useEffect(() => {
    if (estimatedTime !== null) {
      clearInterval(timer); // Limpia temporizadores previos
      timer = setInterval(() => {
        setEstimatedTime((prev) => {
          if (prev === null || prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1; // Reduce un segundo
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Limpieza al desmontar
  }, [estimatedTime]);

  // Actualizar el tiempo mostrado
  useEffect(() => {
    if (estimatedTime !== null) {
      const minutes = Math.floor(estimatedTime / 60);
      const seconds = estimatedTime % 60;
      setDisplayTime(`${minutes}m ${seconds}s`);
      if (estimatedTime === 0 && downloadAllFiles) {
        setDisplayTime(null);
      }
    }
  }, [estimatedTime]);

  useEffect(() => {
    if (downloadAllFiles) {
      dispatch(
        addToast({
          id: "download_document_list_success",
          type: EToastTypes.PRIMARY,
          text: `Download completed successfully!`,
          withClose: true,
          autohide: true,
        }),
      );
      dispatch(removeToast("download_document_list"));
      setDownloadAllFiles(null);
      dispatch(restartSelectedFiles());
    } else {
      dispatch(
        updateToast({
          id: "download_document_list",
          type: EToastTypes.WARNING,
          text: `<div class="d-flex">
              <div class="loader"></div>
              <span> ${estimatedTime && estimatedTime > 0 ? `Preparing for download. Estimated time ${displayTime}. Please do not leave this page.` : "Finishing download. Please do not leave this page."}</span>
            </div>`,
          withClose: false,
          autohide: false,
        }),
      );
    }
  }, [displayTime, downloadAllFiles]);

  useEffect(() => {
    if (!documents) return;
    let newDocuments = documents.data;
    if (selectedFiles.length >= 1) {
      newDocuments = documents.data.map((doc: any) => {
        if (doc.type === null) {
          return {
            ...doc,
            isDisabled: true,
          };
        }
        return {
          ...doc,
        };
      });
    }
    const totalSize = selectedFiles.reduce((acc, { size }) => acc + size, 0);
    const limitSize = 2 * 1073741824;
    if (totalSize > limitSize) {
      const limitSizeToast: IToast = {
        id: "limitSizeToast",
        text: "Your selected files exceed the 2 GB limit. Please remove some files.",
        type: EToastTypes.DANGER,
      };
      dispatch(addToast(limitSizeToast));
      const selectedFileIds = new Set(selectedFiles.map((file) => file.id));
      newDocuments = documents.data.map((doc: any) => {
        if (!selectedFileIds.has(doc.id)) {
          return {
            ...doc,
            isDisabled: true,
          };
        }
        return {
          ...doc,
        };
      });

      setDocumentsData(newDocuments);
    } else {
      setDocumentsData(newDocuments);
      dispatch(removeToast("limitSizeToast"));
    }
  }, [documents, selectedFiles]);

  useEffect(() => {
    if (documents) {
      setDocumentsData(documents.data);
    }
  }, [documents]);

  return (
    <>
      <PinModal pinModal={isOpenPinModal} setPinModal={setIsOpenPinModal} />
      <div className="o-screen-container">
        <Header
          title={documentSection?.data?.name ?? ""}
          actions={pinActionList}
          withGoBack={!!documentSection?.data?.parent_id}
          isLoading={isFetchingDocument || generatingBreadcrumb}
          breadcrumb={breadcrumb}
        />
        {selectedFiles.length > 0 && (
          <div className="d-flex align-items-center mb-4 gap-2 o-cl-grey-100">
            <button
              type="button"
              className="bg-transparent border-0 d-flex px-0"
              onClick={() => {
                if (!isDownloading) {
                  dispatch(restartSelectedFiles());
                }
              }}
            >
              <MaterialIcon
                icon="close"
                size={24}
                color={`${isDownloading ? "o-cl-grey-200" : "o-cl-grey-100"}`}
              />
            </button>
            <span className={`${isDownloading ? "o-cl-grey-200" : ""}`}>
              {selectedFiles.length} selected
            </span>
            <Button
              onClick={handleDownloadMultipleFiles}
              disabled={isDownloading}
              icon={{
                icon: "download",
                size: 18,
                position: "right",
              }}
              value="Download"
            />
          </div>
        )}
        {isFetchingDocument ? (
          <div className="d-flex justify-content-center mt-5">
            <Spinner
              className="v-contacts__spinner"
              color={colors.brandColorSecondary}
            />
          </div>
        ) : (
          <Grid
            data={documentsData || []}
            layout="c-grid--sm-layout"
            actionList={actionList}
            context="documents"
            checkboxes={!isDownloading}
            dynamicCardClassName={(args: IRow) => {
              const { id: cardId } = args;
              let className = "o-bg-transparent";
              if (responsive.md) {
                className = "c-grid__child:hover o-bg-grey-300:hover";
                if (selectedFiles.some((sFile) => isEqual(sFile.id, cardId))) {
                  className = "c-grid__child--selected o-bg-grey-300";
                }
              }
              return className;
            }}
          />
        )}

        {isFetching && <PaginationLoader placeholder="Loading documents..." />}
      </div>
    </>
  );
};

export default DocumentsListSection;
