/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable class-methods-use-this */
import Axios from "axios";
import _ from "lodash";
import history from "@routers/History";
import { clearFranlyLocalStorage } from "@utils/utils";

type TMethodType = "get" | "post" | "put" | "patch" | "delete";
interface IRequest {
  method: TMethodType;
  url: string;
  params?: Object;
}

export const baseURL = process.env.REACT_APP_BACK_BASE_URL;

const unauthorizedStatus = 401;

const axios = Axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": `application/json`,
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Credentials": true,
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");

    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error?.response?.status;

    if (_.isEqual(status, unauthorizedStatus)) {
      if (localStorage.getItem("current_website") === "admin") {
        console.error("error in interceptor", error);
      } else {
        clearFranlyLocalStorage();
        history.replace("/login");
      }
    }

    return Promise.reject(error);
  },
);

export default class HttpProvider {
  request = async (
    method: IRequest["method"],
    url: IRequest["url"],
    params?: IRequest["params"],
  ) => {
    return axios[method](url, params);
  };
}

export const getClientId = () => process.env.REACT_APP_CLIENT_ID;
export const getClientSecret = () => process.env.REACT_APP_CLIENT_SECRET;
