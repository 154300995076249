/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from "react";
import {
  applyFavoriteOrderFilters,
  applyFavoritesFilters,
  nextPage,
  removeFavoriteFilter,
} from "@redux/favorites/thunks/favoritesThunk";
import {
  toggleSelectedFile,
  trackInteractions,
} from "@redux/files/thunks/fileThunk";

import ActionsColumn from "@components/ActionsColumn";
import Checkbox from "@components/Checkbox";
import FileActionButton from "@components/FileActionButton";
import FileName from "@components/FileName";
import { Filter } from "@components/filters/Filter";
import Grid from "@components/Grid";
import HeaderModule from "@components/HeaderModule";
import { IAction } from "@models/Action.type";
import { IFile } from "@models/files/File.type";
import { IFilterDropdown } from "@components/filters/FilterDropdown";
import { IFilterDropdownItem } from "@components/filters/FilterDropdownList";
import { IRow } from "@models/Table.type";
import { ISortListItem } from "@components/filters/SortList";
import PaginationLoader from "@components/PaginationLoader/PaginationLoader";
import { PanelFilter } from "@components/filters/PanelFilter";
import { Sort } from "@components/filters/Sort";
import { Spinner } from "react-activity";
import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import Table from "@components/Table";
import TagList from "@components/FilterTagList";
import _ from "lodash";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import { formatDate } from "@helpers/formatDate";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useHandleFavorite } from "@hooks/useHandleFavorite";
import useWindowSize from "@hooks/useWindowSize";
import { useDownloadOriginalFiles } from "@hooks/useDownloadOriginalFiles";
import Button from "@components/Button";
import { restartSelectedFiles } from "@redux/files/slices/fileSlice";
import {
  addToast,
  removeToast,
  updateToast,
} from "@redux/toasts/slices/toastsSlice";
import { EToastTypes, IToast } from "@models/toast/Toast.type";
import {
  disabledFiles,
  enabledAllFiles,
} from "@redux/favorites/slices/favoriteSlice";

const FavoritesSection = () => {
  const dispatch = useAppDispatch();
  const { responsive } = useWindowSize();
  const { handleFavorite } = useHandleFavorite();
  const {
    toggle: { views },
    favorites: {
      data,
      pending,
      types,
      filters: appliedFilters,
      orderFilter: currentSortId,
      orderFilterName: currentSortName,
      pagination,
    },
  } = useAppSelector((state) => state);
  const {
    file: { selectedFiles },
  } = useAppSelector((state) => state);
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null); // Tiempo en segundos
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  const [downloadAllFiles, setDownloadAllFiles] = useState<boolean | null>(
    null,
  );
  const isDownloading = downloadAllFiles !== null && !downloadAllFiles;
  const { downloadMultipleOriginalFiles, downloadSingleFile } =
    useDownloadOriginalFiles();
  let timer: NodeJS.Timeout;

  const handleDownload = (id: string) => {
    downloadSingleFile(id);
  };

  const actionList: IAction[] = [
    {
      id: "favorite",
      icon: "favorite" as TIcon,
      text: "",
      type: "favorite",
      onClick: (file: IFile) => {
        handleFavorite(file);
      },
    },
    {
      id: "copy-link",
      icon: "file_copy" as TIcon,
      text: "Copy Link",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        if (selectId) {
          navigator.clipboard.writeText(
            `${window.location.origin}/document/${selectId}`,
          );
          createToast("Copied", "success", dispatch);
        }
        dispatch(trackInteractions({ data: [file.id], type: "share" }));
      },
    },
    {
      id: "download_single_favorite",
      icon: "file_download",
      text: "Download",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        handleDownload(`${selectId}`);
      },
    },
  ];

  const columns = [
    {
      header: "",
      id: "checkbox",
      colspan: { xs: "1" },
      field: "",
      body: (file: IRow) => (
        <Checkbox
          item={file as IFile}
          onChange={() => dispatch(toggleSelectedFile(file as IFile))}
          checked={selectedFiles.some((selectedFile) =>
            _.isEqual(selectedFile.id, file.id),
          )}
          showName={false}
        />
      ),
      fixedWidth: { xs: "42px" },
    },
    {
      header: "Name",
      id: "name",
      colspan: { xs: "3" },
      field: "name",
      body: (file: IRow) => <FileName name={file.name} type={file.type} />,
    },
    {
      header: "Date",
      id: "created_at",
      colspan: { xs: "1" },
      field: "created_at",
      parsedString: ({ created_at }: IRow) => formatDate(created_at),
      fixedWidth: { xs: "100px", sm: "unset" },
    },
    {
      header: "Type",
      id: "type",
      colspan: { xs: "1" },
      field: "type",
      parsedString: ({ type }: IRow) => `.${type}`,
      collapsable: true,
    },
    {
      header: "Tags",
      id: "tags",
      colspan: { xs: "1" },
      field: "tags",
      parsedString: ({ tags }: IRow) => (tags ? tags.join(", ") : ""),
      collapsable: true,
    },
    {
      header: "Actions",
      id: "actions",
      colspan: { xs: "1" },
      field: "",
      body: (file: IRow) => (
        <ActionsColumn {...{ file, actionList }} dotsShowLength={1} />
      ),
      hiddenMobileTitle: true,
      fixedWidth: { xs: "40px", lg: "80px" },
    },
  ];

  const filters: any[] = [{ title: "Type", data: types }];
  const module = "favorites";

  useEffect(() => {
    dispatch(
      removeFavoriteFilter({ removedFilter: { id: 0, name: "Clear All" } }),
    );
  }, []);

  const handleDownloadMultipleFiles = () => {
    const downloadToast: IToast = {
      id: "download_favorites",
      text: `<div class="d-flex">
            <div class="loader"></div>
            <span> Preparing for download. Please do not leave this page.</span>
          </div>`,
      type: EToastTypes.WARNING,
      withClose: true,
      autohide: false,
    };
    dispatch(addToast(downloadToast));
    downloadMultipleOriginalFiles(
      selectedFiles.map((file) => {
        return {
          id: file.id,
          size: file.size,
        };
      }),
      (time) => {
        setEstimatedTime(time); // Establecer tiempo estimado total
      },
      setDownloadAllFiles,
    );
  };

  useEffect(() => {
    if (estimatedTime !== null) {
      clearInterval(timer); // Limpia temporizadores previos
      timer = setInterval(() => {
        setEstimatedTime((prev) => {
          if (prev === null || prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1; // Reduce un segundo
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Limpieza al desmontar
  }, [estimatedTime]);

  // Actualizar el tiempo mostrado
  useEffect(() => {
    if (estimatedTime !== null) {
      const minutes = Math.floor(estimatedTime / 60);
      const seconds = estimatedTime % 60;
      setDisplayTime(`${minutes}m ${seconds}s`);
      if (estimatedTime === 0) {
        setDisplayTime(null);
      }
    }
  }, [estimatedTime]);

  useEffect(() => {
    if (selectedFiles.length === 0) return;
    const totalSize = selectedFiles.reduce((acc, { size }) => acc + size, 0);
    const limitSize = 2 * 1073741824;
    if (totalSize > limitSize) {
      const limitSizeToast: IToast = {
        id: "limitSizeToast",
        text: "Your selected files exceed the 2 GB limit. Please remove some files.",
        type: EToastTypes.DANGER,
      };
      dispatch(addToast(limitSizeToast));
      const selectedFileIds = new Set(selectedFiles.map((file) => file.id));
      dispatch(disabledFiles({ filesId: selectedFileIds }));
    } else {
      dispatch(enabledAllFiles());
      dispatch(removeToast("limitSizeToast"));
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (downloadAllFiles) {
      dispatch(
        addToast({
          id: "download_favorites_success",
          type: EToastTypes.PRIMARY,
          text: `Download completed successfully!`,
          withClose: true,
          autohide: true,
        }),
      );
      dispatch(removeToast("download_favorites"));
      setDownloadAllFiles(null);
      dispatch(restartSelectedFiles());
    } else {
      dispatch(
        updateToast({
          id: "download_favorites",
          type: EToastTypes.WARNING,
          text: `<div class="d-flex">
              <div class="loader"></div>
              <span> ${estimatedTime && estimatedTime > 0 ? `Preparing for download. Estimated time ${displayTime}. Please do not leave this page.` : "Finishing download. Please do not leave this page."}</span>
            </div>`,
          withClose: false,
          autohide: false,
        }),
      );
    }
  }, [displayTime, downloadAllFiles]);

  const handleScroll = (event: any) => {
    const { target } = event;
    if (
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1 &&
      target.scrollHeight - target.scrollTop >= target.clientHeight - 1 &&
      pagination.links.next &&
      !pagination.pending
    ) {
      dispatch(nextPage(pagination));
    }
  };

  const renderData = () =>
    views[module].grid ? (
      <Grid
        {...{ data, actionList }}
        checkboxes={!isDownloading}
        dynamicCardClassName={({ id }: IRow) =>
          selectedFiles.some((selectedFile) => _.isEqual(selectedFile.id, id))
            ? "c-grid__child--selected o-bg-grey-300"
            : ""
        }
        layout="c-grid--sm-layout"
        context="favorites"
      />
    ) : (
      <Table
        {...{ data, columns }}
        dynamicRowClassName={({ id }: IRow) => {
          let className = "o-bg-transparent";
          if (responsive.md) {
            className = "o-bg-grey-300:hover";
            if (
              selectedFiles.some((selectedFile) =>
                _.isEqual(selectedFile.id, id),
              )
            ) {
              className = "o-bg-grey-300 c-table__row--selected";
            }
          }
          return className;
        }}
        extraClassName={`${responsive.md && "c-table--selectable mt-md-3"}`}
        // extraClassName="c-table--selectable mt-md-3"
      />
    );
  return (
    <div onScroll={handleScroll} className="o-screen-container">
      <HeaderModule title="Favorites" />
      {selectedFiles.length > 0 && (
        <div className="d-flex align-items-center mb-4 gap-2 o-cl-grey-100">
          <button
            type="button"
            className="bg-transparent border-0 d-flex px-0"
            onClick={() => {
              if (!isDownloading) {
                dispatch(restartSelectedFiles());
              }
            }}
          >
            <MaterialIcon
              icon="close"
              size={24}
              color={`${isDownloading ? "o-cl-grey-200" : "o-cl-grey-100"}`}
            />
          </button>
          <span className={`${isDownloading ? "o-cl-grey-200" : ""}`}>
            {selectedFiles.length} selected
          </span>
          <Button
            onClick={handleDownloadMultipleFiles}
            disabled={isDownloading}
            icon={{
              icon: "download",
              size: 18,
              position: "right",
            }}
            value="Download"
          />
        </div>
      )}
      <PanelFilter
        module={module}
        optionsOnTop
        optionsOnTopMobile
        borderFilter="c-filter-module__filter-border"
        renderFilterWrapper={
          <Filter placeholder="Filter by...">
            <Filter.Dropdown title="Filters">
              {filters.map((item: IFilterDropdown) => (
                <Filter.DropdownList
                  appliedFilters={appliedFilters}
                  key={item.title}
                  title={item.title}
                  list={item.data}
                  onChange={(
                    selectedFilter: IFilterDropdownItem,
                    nextStatus: boolean,
                  ) => {
                    dispatch(
                      applyFavoritesFilters({
                        appliedFilters,
                        selectedFilter,
                        nextStatus,
                      }),
                    );
                  }}
                />
              ))}
            </Filter.Dropdown>
          </Filter>
        }
        renderFilterLayoutWrapper={
          <PanelFilter.FilterLayout switch>
            <>
              <div className="position-relative mx-1">
                <Sort sort={{ id: currentSortId, name: currentSortName }}>
                  <Sort.DropdownList
                    onChange={({ sortId, label }: ISortListItem) =>
                      dispatch(
                        applyFavoriteOrderFilters({
                          appliedFilters,
                          orderingId: sortId,
                          label,
                        }),
                      )
                    }
                  />
                </Sort>
              </div>
              {/* three dots */}
              <FileActionButton text="Download" />
            </>
          </PanelFilter.FilterLayout>
        }
        renderTagsWrapper={
          <TagList
            onClick={(obj: IFilterDropdownItem) =>
              dispatch(
                removeFavoriteFilter({ appliedFilters, removedFilter: obj }),
              )
            }
            list={appliedFilters}
          />
        }
      />

      <div>
        {pending ? (
          <div className="d-flex justify-content-center mt-5">
            <Spinner
              className="o-ft-xl o-ft-3xl@md"
              color={colors.brandColorSecondary}
            />
          </div>
        ) : (
          renderData()
        )}
      </div>

      {pagination.pending && (
        <PaginationLoader placeholder="Loading more results..." />
      )}
    </div>
  );
};

export default FavoritesSection;
