/* eslint-disable @typescript-eslint/no-explicit-any */

import Grid from "@components/Grid";
import { IAction } from "@models/Action.type";
import { IFile } from "@models/files/File.type";
import { IRow } from "@models/Table.type";
import { Spinner } from "react-activity";
import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import _ from "lodash";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import React, { useEffect, useState } from "react";
import { trackInteractions } from "@redux/files/thunks/fileThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useHandleFavorite } from "@hooks/useHandleFavorite";
import useWindowSize from "@hooks/useWindowSize";
import PinModal from "@components/PinModal";
import { usePinUnpinContext } from "../../providers/PinUnpinProvider";
import { useParams } from "react-router-dom";
import { useGetDocuments } from "@redux/documents/api";
import { useDownloadOriginalFiles } from "@hooks/useDownloadOriginalFiles";
import Button from "@components/Button";
import { restartSelectedFiles } from "@redux/files/slices/fileSlice";
import {
  addToast,
  removeToast,
  updateToast,
} from "@redux/toasts/slices/toastsSlice";
import { EToastTypes, IToast } from "@models/toast/Toast.type";

const VaultDocumentsSection = () => {
  const { "*": idDocuments } = useParams<{ "*": string }>();
  const [lastDocumentId, setLastDocumentId] = useState<number | null>(null);
  useEffect(() => {
    const idDocumentsArray = idDocuments?.split("/");
    const lastDocument =
      idDocumentsArray && idDocumentsArray.length
        ? idDocumentsArray[idDocumentsArray.length - 1]
        : null;

    setLastDocumentId(Number(lastDocument));
  }, [idDocuments]);

  const { data: documents, isFetching } = useGetDocuments(
    {
      parentId: lastDocumentId,
    },
    {
      skip: lastDocumentId === null,
    },
  );
  const { selectedFiles } = useAppSelector((state) => state.file);
  const { saved: favorites } = useAppSelector((state) => state.favorites);
  const { isOpenPinModal, setIsOpenPinModal } = usePinUnpinContext();
  const dispatch = useAppDispatch();
  const [documentsData, setDocumentsData] = useState<any>(null);
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null); // Tiempo en segundos
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  const { downloadMultipleOriginalFiles, downloadSingleFile } =
    useDownloadOriginalFiles();
  let timer: NodeJS.Timeout;
  const { handleFavorite } = useHandleFavorite();
  const [downloadAllFiles, setDownloadAllFiles] = useState<boolean | null>(
    null,
  );
  const isDownloading = downloadAllFiles !== null && !downloadAllFiles;
  const fileDownloadIcon: TIcon = "download";

  const { responsive } = useWindowSize();

  const handleDownload = (id: string) => {
    downloadSingleFile(id);
  };

  const actionList: IAction[] = [
    {
      id: "favorite",
      icon: "favorite" as TIcon,
      text: "",
      type: "favorite",
      onClick: (file: IFile) => {
        handleFavorite(file);
      },
    },
    {
      id: "copy-link",
      icon: "file_copy" as TIcon,
      text: "Copy Link",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        if (selectId) {
          navigator.clipboard.writeText(
            `${window.location.origin}/document/${selectId}`,
          );
          createToast("Copied", "success", dispatch);
        }
        dispatch(trackInteractions({ data: [file.id], type: "share" }));
      },
    },
    {
      id: "download_single_vault",
      icon: fileDownloadIcon,
      text: "Download",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        handleDownload(`${selectId}`);
      },
    },
  ];

  const handleDownloadMultipleFiles = () => {
    const downloadToast: IToast = {
      id: "download_vaults",
      text: `<div class="d-flex">
            <div class="loader"></div>
            <span> Preparing for download. Please do not leave this page.</span>
          </div>`,
      type: EToastTypes.WARNING,
      withClose: true,
      autohide: false,
    };
    dispatch(addToast(downloadToast));
    downloadMultipleOriginalFiles(
      selectedFiles.map((file) => {
        return {
          id: file.id,
          size: file.size,
        };
      }),
      (time) => {
        setEstimatedTime(time); // Establecer tiempo estimado total
      },
      setDownloadAllFiles,
    );
  };

  useEffect(() => {
    if (estimatedTime !== null) {
      clearInterval(timer); // Limpia temporizadores previos
      timer = setInterval(() => {
        setEstimatedTime((prev) => {
          if (prev === null || prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1; // Reduce un segundo
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Limpieza al desmontar
  }, [estimatedTime]);

  // Actualizar el tiempo mostrado
  useEffect(() => {
    if (estimatedTime !== null) {
      const minutes = Math.floor(estimatedTime / 60);
      const seconds = estimatedTime % 60;
      setDisplayTime(`${minutes}m ${seconds}s`);
      if (estimatedTime === 0) {
        setDisplayTime(null);
      }
    }
  }, [estimatedTime]);

  useEffect(() => {
    if (downloadAllFiles) {
      dispatch(
        addToast({
          id: "download_vaults_success",
          type: EToastTypes.PRIMARY,
          text: `Download completed successfully!`,
          withClose: true,
          autohide: true,
        }),
      );
      dispatch(removeToast("download_vaults"));
      setDownloadAllFiles(null);
      dispatch(restartSelectedFiles());
    } else {
      dispatch(
        updateToast({
          id: "download_vaults",
          type: EToastTypes.WARNING,
          text: `<div class="d-flex">
              <div class="loader"></div>
              <span> ${estimatedTime && estimatedTime > 0 ? `Preparing for download. Estimated time ${displayTime}. Please do not leave this page.` : "Finishing download. Please do not leave this page."}</span>
            </div>`,
          withClose: false,
          autohide: false,
        }),
      );
    }
  }, [displayTime, downloadAllFiles]);

  useEffect(() => {
    if (!documents) return;
    let newDocuments = documents.data;
    if (selectedFiles.length >= 1) {
      newDocuments = documents.data.map((doc: any) => {
        if (doc.type === null) {
          return {
            ...doc,
            isDisabled: true,
          };
        }
        return {
          ...doc,
        };
      });
    }
    const totalSize = selectedFiles.reduce((acc, { size }) => acc + size, 0);
    const limitSize = 2 * 1073741824;
    if (totalSize > limitSize) {
      const limitSizeToast: IToast = {
        id: "limitSizeToast",
        text: "Your selected files exceed the 2 GB limit. Please remove some files.",
        type: EToastTypes.DANGER,
      };
      dispatch(addToast(limitSizeToast));
      const selectedFileIds = new Set(selectedFiles.map((file) => file.id));
      newDocuments = documents.data.map((doc: any) => {
        if (!selectedFileIds.has(doc.id)) {
          return {
            ...doc,
            isDisabled: true,
          };
        }
        return {
          ...doc,
        };
      });

      setDocumentsData(newDocuments);
    } else {
      setDocumentsData(newDocuments);
      dispatch(removeToast("limitSizeToast"));
    }
  }, [documents, selectedFiles]);

  useEffect(() => {
    if (documents) {
      setDocumentsData(documents.data);
    }
  }, [documents]);

  return (
    <div>
      <PinModal pinModal={isOpenPinModal} setPinModal={setIsOpenPinModal} />
      {selectedFiles.length > 0 && (
        <div className="d-flex align-items-center mb-4 gap-2 o-cl-grey-100">
          <button
            type="button"
            className="bg-transparent border-0 d-flex px-0"
            onClick={() => {
              if (!isDownloading) {
                dispatch(restartSelectedFiles());
              }
            }}
          >
            <MaterialIcon
              icon="close"
              size={24}
              color={`${isDownloading ? "o-cl-grey-200" : "o-cl-grey-100"}`}
            />
          </button>
          <span className={`${isDownloading ? "o-cl-grey-200" : ""}`}>
            {selectedFiles.length} selected
          </span>
          <Button
            onClick={handleDownloadMultipleFiles}
            disabled={isDownloading}
            icon={{
              icon: "download",
              size: 18,
              position: "right",
            }}
            value="Download"
          />
        </div>
      )}
      {isFetching ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      ) : (
        <Grid
          checkboxes={!isDownloading}
          data={documentsData ?? []}
          layout="c-grid--sm-layout"
          {...{ actionList, favorites }}
          dynamicCardClassName={({ id, type }: IRow) => {
            let className = "o-bg-transparent";
            if (responsive.md && type) {
              className = "";
              if (
                selectedFiles.some((selectedFile: any) =>
                  _.isEqual(selectedFile.id, id),
                )
              ) {
                className = "c-grid__child--selected o-bg-grey-300";
              }
            }
            return className;
          }}
        />
      )}
    </div>
  );
};

export default VaultDocumentsSection;
