/* eslint-disable react/no-unstable-nested-components */
import { formatDate } from "@helpers/formatDate";
import useAppSelector from "@hooks/useAppSelector";
import useWindowSize from "@hooks/useWindowSize";
import { IRow } from "@models/Table.type";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Table from "@components/Table";
import FileName from "./FileName";
import { getLastEditedFiles } from "@redux/files/thunks/fileThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import MaterialIcon, { TIcon } from "./MaterialIcon";
import { IFile } from "@models/files/File.type";
import ActionsColumn from "./ActionsColumn";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import { useDownloadOriginalFiles } from "@hooks/useDownloadOriginalFiles";

const ActivitySection = () => {
  const { responsive } = useWindowSize();
  const { downloadSingleFile } = useDownloadOriginalFiles();
  const {
    file: { selectedFiles },
  } = useAppSelector((state) => state);
  const {
    file: { pending, lastEdited },
  } = useAppSelector((state) => state);
  const [count, setCount] = useState(true);
  const dispatch = useAppDispatch();
  const fileDownloadIcon: TIcon = "file_download";

  const handleDownload = (id: string) => {
    downloadSingleFile(id);
  };

  const list = [
    {
      id: "download",
      icon: fileDownloadIcon,
      text: "Download",
      onClick: (file: IFile) => handleDownload(`${file.id}`),
    },
  ];

  const columns = [
    {
      header: "Name",
      id: "name",
      colspan: { xs: "2", sm: "4" },
      field: "name",
      body: (file: IRow) => <FileName name={`${file.name}`} type={file.type} />,
    },
    {
      header: "Date",
      id: "updated_at",
      colspan: { xs: "2" },
      field: "updated_at",
      parsedString: ({ updated_at }: IRow) => formatDate(updated_at),
      fixedWidth: { xs: "100px", sm: "3" },
      collapsable: true,
    },
    {
      header: "Type",
      id: "type",
      colspan: { sm: "1" },
      field: "type",
      parsedString: ({ type }: IRow) => `.${type}`,
      collapsable: true,
    },
    {
      header: "Location",
      id: "location",
      colspan: { xs: "2", sm: "3" },
      field: "location",
      parsedString: (file: IRow) => file.path.split(`/${file.name}`)[0],
    },
    {
      header: "",
      id: "actions",
      colspan: { xs: "1" },
      field: "",
      body: (file: IRow) => (
        <ActionsColumn
          {...{ file }}
          actionList={list}
          dotsShowLength={1}
          dotsShow={false}
        />
      ),
      hiddenMobileTitle: true,
      fixedWidth: { xs: "40px", lg: "212px" },
      collapsable: true,
    },
  ];

  useEffect(() => {
    dispatch(getLastEditedFiles());
  }, [dispatch]);

  return (
    <div className="c-activity-section">
      <div className="c-activity-section__title" id="lastEditedOnboarding">
        <span className="o-ft-3xl-600  o-cl-grey-100">Last Edited</span>
      </div>
      {pending ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      ) : (
        <Table
          dynamicRowClassName={({ id }: IRow) => {
            let className = "o-bg-transparent";
            if (responsive.md) {
              className = "o-bg-grey-300:hover";
              if (
                selectedFiles.some((selectedFile) =>
                  _.isEqual(selectedFile.id, id),
                )
              ) {
                className = "o-bg-grey-300 c-table__row--selected";
              }
            }
            return className;
          }}
          columns={columns}
          data={lastEdited.slice(0, count ? 5 : 10)}
          extraClassName={`${responsive.md && "c-table--selectable mt-md-3"}`}
        />
      )}
      {lastEdited.length > 5 && (
        <div
          className="c-activity-section__view-more-button o-ft-xs-700 o-cl-grey-100 d-flex justify-content-center align-items-center o-cursor-pointer"
          onClick={() => setCount(!count)}
          aria-hidden
        >
          <div className="d-flex align-items-center">
            {count ? "View More" : "View Less"}
            <MaterialIcon
              icon={count ? "expand_more" : "expand_less"}
              size={24}
              color="o-cl-grey-100"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivitySection;
