/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

export interface IAllContacts {
  page?: number;
  orderBy?: string;
  orderDirection?: "asc" | "desc";
  tags?: string[];
  query?: string;
}

export const contactsApi = createApi({
  reducerPath: "contactsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getContacts: builder.query<any, IAllContacts>({
      query: ({
        page,
        orderBy = "created_at",
        orderDirection = "desc",
        tags,
      }) => {
        const queryParams = [
          `page=${page}`,
          orderBy ? `orderBy=${orderBy}` : "",
          orderDirection ? `orderDirection=${orderDirection}` : "",
          tags
            ? tags.map((tag) => `filters[contact_tags][]=${tag}`).join("&")
            : "",
        ];

        return `api/contacts?${queryParams.filter(Boolean).join("&")}`;
      },
    }),
    getContactsBySearch: builder.query<any, IAllContacts>({
      query: ({ tags, query }) => {
        const queryParams = [
          query ? `filters[q]=${query}` : "",
          tags
            ? tags.map((tag) => `filters[contact_tags][]=${tag}`).join("&")
            : "",
        ];

        return `api/contacts?${queryParams.filter(Boolean).join("&")}`;
      },
      transformResponse: (res: any) => res.data,
    }),
    getTypes: builder.query<any, void>({
      query: () => ({ url: "api/contact-types" }),
      transformResponse: (res: any) => res.data,
    }),
    getContactTagCategories: builder.query<any, void>({
      query: () => ({
        url: "api/contact-tag-categories",
      }),
      transformResponse: (res: any) => res.data,
    }),
  }),
});

export const {
  useGetContactsQuery: useGetContacts,
  useGetContactsBySearchQuery: useGetContactsBySearch,
  useGetTypesQuery: useGetTypes,
  useGetContactTagCategoriesQuery: useGetContactTagCategories,
} = contactsApi;
