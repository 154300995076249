import React, { useContext, useState, useEffect } from "react";

import FileName from "./FileName";
import MaterialIcon from "./MaterialIcon";
import { ProcessStepContext } from "./ProcessStep";
import {
  useDownloadOriginalFiles,
  IDownloadableFile,
} from "@hooks/useDownloadOriginalFiles";
import {
  addToast,
  updateToast,
  removeToast,
} from "@redux/toasts/slices/toastsSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import { EToastTypes, IToast } from "@models/toast/Toast.type";

const StepAttachments = () => {
  const {
    step: { files, documents },
  } = useContext(ProcessStepContext);
  const dispatch = useAppDispatch();
  const attachments = [...(files || []), ...(documents || [])];
  const { downloadMultipleOriginalFiles, downloadSingleFile } =
    useDownloadOriginalFiles();
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null); // Tiempo en segundos
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  const [downloadAllFiles, setDownloadAllFiles] = useState<boolean | null>(
    null,
  );
  let timer: NodeJS.Timeout;
  const handleDownloadAll = () => {
    const downloadToast: IToast = {
      id: "download_attachments",
      text: `<div class="d-flex">
            <div class="loader"></div>
            <span> Preparing for download. Please do not leave this page. </span>
          </div>`,
      type: EToastTypes.WARNING,
      withClose: true,
      autohide: false,
    };
    dispatch(addToast(downloadToast));
    const filesToDownload = attachments.map((at) => {
      const { id, file } = at;

      return {
        id,
        size: file.size,
      };
    });
    downloadMultipleOriginalFiles(
      filesToDownload,
      (time) => {
        setEstimatedTime(time);
      },
      setDownloadAllFiles,
    );
  };

  const handleDownloadFile = (file: IDownloadableFile) => {
    downloadSingleFile(file.id);
  };

  const renderAttachments = attachments.map((attachment) => {
    const { id, original_name: originalName, name, type, file } = attachment;
    const attachmentName =
      name ||
      originalName ||
      file?.original_name?.substring(0, file?.original_name.lastIndexOf(".")) ||
      "";
    const attachmentType =
      type ||
      file?.original_name?.split(".")[
        file.original_name.split(".").length - 1
      ] ||
      "";

    useEffect(() => {
      if (estimatedTime !== null) {
        clearInterval(timer); // Limpia temporizadores previos
        timer = setInterval(() => {
          setEstimatedTime((prev) => {
            if (prev === null || prev <= 1) {
              clearInterval(timer);
              return 0;
            }
            return prev - 1; // Reduce un segundo
          });
        }, 1000);
      }

      return () => clearInterval(timer); // Limpieza al desmontar
    }, [estimatedTime]);

    // Actualizar el tiempo mostrado
    useEffect(() => {
      if (estimatedTime !== null) {
        const minutes = Math.floor(estimatedTime / 60);
        const seconds = estimatedTime % 60;
        setDisplayTime(`${minutes}m ${seconds}s`);
        if (estimatedTime === 0) {
          setDisplayTime(null);
        }
      }
    }, [estimatedTime]);

    return (
      <div key={id} className="m-3 d-flex justify-content-between">
        <FileName name={attachmentName} type={attachmentType} />
        <MaterialIcon
          color="o-cl-grey-200 o-cursor-pointer"
          icon="download"
          size={24}
          onClick={() =>
            handleDownloadFile({
              id: attachment.id,
              size: attachment.size,
            })
          }
        />
      </div>
    );
  });

  useEffect(() => {
    if (downloadAllFiles) {
      dispatch(
        addToast({
          id: "download_attachments_success",
          type: EToastTypes.PRIMARY,
          text: `Download completed successfully!`,
          withClose: true,
          autohide: true,
        }),
      );
      setDownloadAllFiles(null);
      dispatch(removeToast("download_attachments"));
    } else {
      dispatch(
        updateToast({
          id: "download_attachments",
          type: EToastTypes.WARNING,
          text: `<div class="d-flex">
              <div class="loader"></div>
              <span> ${estimatedTime && estimatedTime > 0 ? `Preparing for download. Estimated time ${displayTime}. Please do not leave this page.` : "Finishing download. Please do not leave this page."}</span>
            </div>`,
          withClose: false,
          autohide: false,
        }),
      );
    }
  }, [displayTime, downloadAllFiles]);

  return (
    <div className="">
      {!!attachments.length && (
        <div>
          <span className="o-ft-sm-700 o-cl-grey-100">Attachments:</span>

          <button
            type="button"
            onClick={handleDownloadAll}
            className="c-process-step__download-button o-cursor d-flex align-items-center"
          >
            <span className="c-process-step__download-button-text o-ft-xs-600 o-cl-grey-100">
              Download all
            </span>
            <MaterialIcon color="o-cl-grey-100" icon="download" size={24} />
          </button>

          <div className="c-step-attachments">
            <div className="c-step-attachments__section">
              <div className="c-step-attachments__content">
                {renderAttachments}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepAttachments;
