declare global {
  interface Navigator {
    connection?: {
      downlink: number; // Velocidad de descarga en Mbps
      effectiveType?: string; // Tipo de conexión (por ejemplo, '4g', '3g')
    };
    mozConnection?: Navigator["connection"];
    webkitConnection?: Navigator["connection"];
  }
}
/* eslint-disable no-plusplus */
export function fixUrl(url: string): string {
  return url.replace(/\\/g, "");
}

// Función para truncar el texto
export const truncateText = (text: string, wordLimit: number): string => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return `${words.slice(0, wordLimit).join(" ")}...`;
  }
  return text;
};

export const truncateTextToFit = (
  text: string,
  maxHeight: number,
  maxWidth: number,
  id: string,
  height: string = "75px",
): string => {
  const measurer = document.getElementById(id);

  if (!measurer) {
    return text; // Si no se encuentra el contenedor de medición, devuelve el texto original
  }

  measurer.style.display = "block"; // Muestra el contenedor para medir
  measurer.style.width = `${maxWidth}px`;
  measurer.innerText = text;

  if (measurer.offsetHeight <= maxHeight) {
    measurer.style.height = height;
    measurer.style.display = "block"; // Oculta el contenedor nuevamente
    return text;
  }

  const words = text.split(" ");
  let truncatedText = "";

  for (let i = 0; i < words.length; i++) {
    truncatedText += `${words[i]} `;
    measurer.innerText = `${truncatedText.trim()}...`;

    if (measurer.offsetHeight > maxHeight) {
      return `${truncatedText.trim()}...`;
    }
  }

  measurer.style.display = "none"; // Oculta el contenedor nuevamente
  return text;
};

export const base64ToFile = (base64String: string, filename: string) => {
  // Extrae el tipo MIME de la cadena base64
  const mimeTypeMatch = base64String.match(
    /^data:([a-zA-Z0-9+_.-]+\/[a-zA-Z0-9+_.-]+);base64,/,
  );
  const mimeType = mimeTypeMatch ? mimeTypeMatch[1] : "";

  // Elimina el prefijo de data URL
  const base64Data = base64String.replace(
    /^data:[a-zA-Z0-9+_.-]+\/[a-zA-Z0-9+_.-]+;base64,/,
    "",
  );

  // Convierte base64 a un array de bytes
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Crea un Blob a partir del array de bytes
  const blob = new Blob([byteArray], { type: mimeType });

  // Crea un objeto File a partir del Blob
  return new File([blob], filename, { type: mimeType });
};

export const getEstimatedTime = (totalSizeInBytes: number): string => {
  // Obtener velocidad estimada de conexión (Mbps -> convertir a Bps)
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  const defaultSpeedMbps = 5; // Velocidad predeterminada (en Mbps) si no se detecta conexión
  const speedMbps = connection?.downlink || defaultSpeedMbps; // Downlink en Mbps
  const speedBps = speedMbps * 1024 * 1024; // Convertir Mbps a Bytes por segundo

  // Calcular el tiempo estimado en segundos
  const estimatedTimeSeconds = totalSizeInBytes / speedBps;

  // Convertir a formato legible (minutos y segundos)
  const minutes = Math.floor(estimatedTimeSeconds / 60);
  const seconds = Math.round(estimatedTimeSeconds % 60);

  return `${minutes}m ${seconds}s`;
};

export const urlToFile = async (url: string, filename: string) => {
  // Descargar el archivo desde la URL como un Blob
  const response = await fetch(url);

  // Verificar si la respuesta es exitosa
  if (!response.ok) {
    throw new Error(`Error al descargar el archivo: ${response.statusText}`);
  }

  const blob = await response.blob();

  // Crear un objeto File a partir del Blob
  const file = new File([blob], filename, {
    type: blob.type, // Mantén el tipo MIME original
  });

  return file;
};

export const clearFranlyLocalStorage = () => {
  const prefixesToKeep = [
    "us-TimesShown",
    "us-ShowAgainAction",
    "us-ShowAgainTimestamp",
  ];

  const keysToRemove = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key && !prefixesToKeep.some((prefix) => key.startsWith(prefix))) {
      keysToRemove.push(key);
    }
  }

  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });
};
