/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { RefObject, useState, useEffect } from "react";

import ActionModal from "./ActionModal";
import AddFolderModal from "./AddFolderModal";
import AdminHeaderModule from "./AdminHeaderModule";
import AdminSearchBar from "./AdminSearchBar";
import Button from "@components/Button";
import Chip from "./Chip";
import DotsMenu from "./DotsMenu";
import FileUpload from "@components/FileUpload";
import FolderFilter from "./filters/FolderFilter";
import { ISortListItem } from "./filters/SortList";
import { Sort } from "./filters/Sort";
import SwitchMode from "@components/SwitchMode";
import _ from "lodash";
import classNames from "classnames";
import { deleteFiles } from "@redux/files/thunks/fileThunk";
import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";
import {
  setFilters,
  disabledFiles,
  enabledAllFolders,
  restartSelectedFiles,
} from "@redux/files/slices/fileSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useLocation } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";
import { useDownloadOriginalFiles } from "@hooks/useDownloadOriginalFiles";
import {
  addToast,
  removeToast,
  updateToast,
} from "@redux/toasts/slices/toastsSlice";
import { EToastTypes, IToast } from "@models/toast/Toast.type";

interface IOrderingProps {
  orderFilter: number | null;
  orderFilterName: string | null;
  orderingFunction?: (obj: any) => void;
}
interface IAdminHeaderProps {
  title?: string;
  folder?: any;
  actionList?: any;
  handleFileLoad?: (e: React.ChangeEvent) => void;
  uploadButtonRef?: RefObject<HTMLInputElement | null>;
  ordering: IOrderingProps;
  handleUploadFolder?: (e: React.ChangeEvent) => void;
}

interface IUploadFileProps {
  downloading?: boolean;
  handleFileLoad: (e: React.ChangeEvent) => void;
  uploadButtonRef?: RefObject<HTMLInputElement | null>;
  size?: "small" | "medium" | "medium-big" | "big";
  hasIcon?: boolean;
  value?: string;
  className?: string;
}

export const UploadFile = ({
  downloading,
  handleFileLoad,
  uploadButtonRef,
  size,
  hasIcon,
  value,
  className,
}: IUploadFileProps) => {
  return (
    <FileUpload
      disabled={downloading}
      className={`me-2 ${className}`}
      handleFileDocument={handleFileLoad}
      icon={{
        icon: "upload",
        size: 18,
      }}
      size={size}
      hasIcon={hasIcon}
      id="add-file-header"
      ref={uploadButtonRef}
      value={value as any}
    />
  );
};

const AdminHeader = ({
  handleFileLoad,
  handleUploadFolder,
  uploadButtonRef,
  actionList,
  folder,
  title,
  ordering: { orderFilter, orderFilterName, orderingFunction },
}: IAdminHeaderProps) => {
  const { data: user } = useAppSelector((state) => state.user);
  const {
    toggle: { views },
  } = useAppSelector((state) => state);
  const {
    trash: { pending: trashPending },
  } = useAppSelector((state) => state);
  const { selectedFiles, data, pendingDelete, folderFilters, downloading } =
    useAppSelector((state) => state.file);
  const { downloadMultipleOriginalFiles } = useDownloadOriginalFiles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { responsive } = useWindowSize();
  const adminListMode = views.admin.list;
  const parentId = data?.parent?.id;
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null); // Tiempo en segundos
  const [downloadAllFiles, setDownloadAllFiles] = useState<boolean | null>(
    null,
  );
  const isDownloading = downloadAllFiles !== null && !downloadAllFiles;
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  let timer: NodeJS.Timeout;
  const switchModeContainerClass = classNames({
    "me-2": !responsive.md,
  });

  const handleCreate = () => {
    setModalCreateVisible(true);
  };

  const handleDownload = () => {
    const downloadToast: IToast = {
      id: "download_admin",
      text: `<div class="d-flex">
            <div class="loader"></div>
            <span> Preparing for download. Please do not leave this page.</span>
          </div>`,
      type: EToastTypes.WARNING,
      withClose: true,
      autohide: false,
    };
    dispatch(addToast(downloadToast));
    downloadMultipleOriginalFiles(
      selectedFiles.map((file) => {
        return {
          id: file.id,
          size: file.file.size,
        };
      }),
      (time) => {
        setEstimatedTime(time); // Establecer tiempo estimado total
      },
      setDownloadAllFiles,
    );
  };

  const handleDelete = async () => {
    await dispatch(deleteFiles(selectedFiles));
    setModalDeleteVisible(false);
  };

  const isFileManagement = () => {
    const inFileManagement =
      _.isEqual(location.pathname, "/admin/file-management") ||
      _.isEqual(location.pathname, "/admin/file-management/");
    const isResults =
      _.isEqual(location.pathname, "/admin/file-management/results") ||
      _.isEqual(location.pathname, "/admin/file-management/results/");
    if (!folder?.id && !(inFileManagement || isResults)) return false;
    return true;
  };

  const renderList = () =>
    adminListMode ? (
      // Re add class me-3 when uncommenting Order & Dots
      <SwitchMode module="admin">
        <div className={`ms-1 d-flex ${switchModeContainerClass}`}>
          <SwitchMode.Icon mode="grid" type="mobile" />
        </div>
      </SwitchMode>
    ) : (
      // Re add class me-3 when uncommenting Order & Dots
      <SwitchMode module="admin">
        <div className={`ms-1 d-flex ${switchModeContainerClass}`}>
          <SwitchMode.Icon mode="list" type="mobile" />
        </div>
      </SwitchMode>
    );

  useEffect(() => {
    if (estimatedTime !== null) {
      clearInterval(timer); // Limpia temporizadores previos
      timer = setInterval(() => {
        setEstimatedTime((prev) => {
          if (prev === null || prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1; // Reduce un segundo
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Limpieza al desmontar
  }, [estimatedTime]);

  // Actualizar el tiempo mostrado
  useEffect(() => {
    if (estimatedTime !== null) {
      const minutes = Math.floor(estimatedTime / 60);
      const seconds = estimatedTime % 60;
      setDisplayTime(`${minutes}m ${seconds}s`);
      if (estimatedTime === 0) {
        setDisplayTime(null);
      }
    }
  }, [estimatedTime]);

  useEffect(() => {
    if (selectedFiles.length === 0) return;
    const totalSize = selectedFiles.reduce(
      (acc, { file }) => acc + file.size,
      0,
    );
    const limitSize = 2 * 1073741824;
    if (totalSize > limitSize) {
      const limitSizeToast: IToast = {
        id: "limitSizeToast",
        text: "Your selected files exceed the 2 GB limit. Please remove some files.",
        type: EToastTypes.DANGER,
      };
      dispatch(addToast(limitSizeToast));
      const selectedFileIds = new Set(selectedFiles.map((file) => file.id));
      dispatch(disabledFiles({ filesId: selectedFileIds }));
    } else {
      dispatch(enabledAllFolders());
      dispatch(removeToast("limitSizeToast"));
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (downloadAllFiles) {
      dispatch(
        addToast({
          id: "download_admin_success",
          type: EToastTypes.PRIMARY,
          text: `Download completed successfully!`,
          withClose: true,
          autohide: true,
        }),
      );
      dispatch(removeToast("download_admin"));
      dispatch(restartSelectedFiles());
      setDownloadAllFiles(null);
    } else {
      dispatch(
        updateToast({
          id: "download_admin",
          type: EToastTypes.WARNING,
          text: `<div class="d-flex">
              <div class="loader"></div>
              <span> ${estimatedTime && estimatedTime > 0 ? `Preparing for download. Estimated time ${displayTime}. Please do not leave this page.` : "Finishing download. Please do not leave this page."}</span>
            </div>`,
          withClose: false,
          autohide: false,
        }),
      );
    }
  }, [displayTime, downloadAllFiles]);

  return (
    <>
      <AddFolderModal
        folderId={data.id}
        isVisible={modalCreateVisible}
        setIsVisible={setModalCreateVisible}
      />
      <ActionModal
        isLoading={pendingDelete}
        show={modalDeleteVisible}
        title="Delete File"
        subtitle={
          <>
            Are you sure you want to delete{" "}
            <b>
              {selectedFiles.length}{" "}
              {selectedFiles.length > 1 ? "Files" : "File"}
            </b>{" "}
            from File Management?
          </>
        }
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setModalDeleteVisible(false)}
        onClickRightButton={handleDelete}
      />
      <AdminHeaderModule {...{ actionList, folder, title }} />
      <div>
        {!responsive.md && isFileManagement() && (
          <div className="mb-3">
            <AdminSearchBar />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-start w-100">
          {responsive.md && (
            <div className="me-2">
              <FolderFilter />
            </div>
          )}
          {handleFileLoad &&
            uploadButtonRef &&
            user?.permissions.Document?.includes("write") && (
              <UploadFile
                downloading={downloading || isDownloading}
                handleFileLoad={handleFileLoad}
                uploadButtonRef={uploadButtonRef}
              />
            )}
          {handleUploadFolder &&
            user?.permissions.Document?.includes("write") && (
              <FileUpload
                disabled={downloading || isDownloading}
                className="me-2"
                handleFileDocument={handleUploadFolder}
                icon={{
                  icon: "upload",
                  size: 18,
                }}
                id="add-folder-header"
                value="Upload Folder"
                webkitdirectory=""
              />
            )}
          {!_.isEqual(title, "Results") &&
            user?.permissions.Document?.includes("write") && (
              <Button
                onClick={handleCreate}
                className="ms-md-1"
                icon={{
                  icon: "folder_open",
                  size: 18,
                  position: "right",
                }}
                value="Add Folder"
                disabled={downloading || isDownloading}
              />
            )}
          {responsive.md && !_.isEmpty(selectedFiles) && (
            <div
              className={`d-flex flex-row ${user?.permissions.Document?.includes("write") ? "border-start border-dark ms-3" : ""} `}
            >
              {!selectedFiles.some((file) => !file.file_id) && (
                <Button
                  onClick={handleDownload}
                  className={
                    user?.permissions.Document?.includes("write") ? "ms-3" : ""
                  }
                  icon={{
                    icon: "download",
                    size: 18,
                    position: "right",
                  }}
                  disabled={isDownloading}
                  value="Download"
                />
              )}
              {user?.permissions.Document?.includes("write") && (
                <Button
                  onClick={() => setModalDeleteVisible(true)}
                  className="ms-3"
                  icon={{
                    icon: "delete",
                    size: 18,
                    position: "right",
                  }}
                  value="Delete"
                  disabled={isDownloading}
                />
              )}
            </div>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center">
            {responsive.md ? (
              <SwitchMode module="admin">
                <div className="ms-2 me-2 d-flex">
                  <SwitchMode.Icon mode="grid" />
                </div>
                <div className="mx-1 d-flex">
                  <SwitchMode.Icon mode="list" />
                </div>
              </SwitchMode>
            ) : (
              renderList()
            )}
            {/* {responsive.md ? (
              <SwitchMode module="admin">
                <div className="ms-2 me-2 d-flex">
                  <SwitchMode.Icon mode="grid" />
                </div>
                <div className="mx-1 d-flex">
                  <SwitchMode.Icon mode="list" />
                </div>
              </SwitchMode>
            ) : adminListMode ? (
              // Re add class me-3 when uncommenting Order & Dots
              <SwitchMode module="admin">
                <div className={`ms-1 d-flex ${switchModeContainerClass}`}>
                  <SwitchMode.Icon mode="grid" type="mobile" />
                </div>
              </SwitchMode>
            ) : (
              // Re add class me-3 when uncommenting Order & Dots
              <SwitchMode module="admin">
                <div className={`ms-1 d-flex ${switchModeContainerClass}`}>
                  <SwitchMode.Icon mode="list" type="mobile" />
                </div>
              </SwitchMode>
            )} */}
            {orderingFunction && (
              <div className="position-relative mx-md-2">
                <Sort
                  collapsible
                  sort={{ id: orderFilter, name: orderFilterName }}
                >
                  <Sort.DropdownList
                    onChange={({ sortId, label }: ISortListItem) =>
                      orderingFunction({ orderingId: sortId, parentId, label })
                    }
                  />
                </Sort>
              </div>
            )}
            {/* <div className='position-relative mx-md-2'>
              <Order collapsable={true} />
            </div> */}
            {actionList && (
              <div className="d-none d-md-block">
                <DotsMenu
                  pending={trashPending}
                  dotsDirection="vertical"
                  actionList={actionList}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {!responsive.md && (
        <div className="mt-4">
          <FolderFilter />

          <div className="d-flex gap-2 mt-4">
            {folderFilters.map((filter) => (
              <Chip
                key={filter}
                name={formatCapitalizeFirstLetter(filter)}
                onClose={() =>
                  dispatch(
                    setFilters(folderFilters.filter((f) => f !== filter)),
                  )
                }
                size="small"
              />
            ))}
            {folderFilters.length > 0 && (
              <Chip
                name="Clear All"
                onClose={() => dispatch(setFilters([]))}
                size="small"
                main
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default AdminHeader;
