import * as Yup from "yup";

import { Formik, Form as FormikForm } from "formik";
import React, { useState } from "react";

import AuthController from "@controllers/AuthController";
import Button from "@components/Button";
import ButtonGoBack from "@components/ButtonGoBack";
import FormCaption from "@components/FormCaption";
import FormDescription from "@components/FormDescription";
import FormError from "@components/FormError";
import FormHeader from "@components/FormHeader";
import FormLinks from "@components/FormLinks";
import FormTextLink from "@components/FormTextLink";
import FormView from "@components/FormView";
import { Link } from "react-router-dom";
import TextField from "@components/TextField";
import _ from "lodash";
import { useIubenda } from "@hooks/useIubenda";
import { clearFranlyLocalStorage } from "@utils/utils";

const initialValues = {
  email: "",
};
interface IForgotForm {
  email: string;
}

const ForgotSchema = Yup.object().shape({
  email: Yup.string().email("Email is invalid").required("Email is required"),
});

const ForgotPassword = () => {
  useIubenda();

  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorNotification, setErrorNotification] = useState<boolean>(false);

  const onError = () => {
    clearFranlyLocalStorage();
    setIsLoading(false);
    setErrorNotification(true);
  };

  const onSuccess = () => {
    clearFranlyLocalStorage();
    setIsLoading(false);
    setIsSuccess(true);
  };

  const onSubmit = (values: IForgotForm) => {
    setEmail(values.email);
    setIsLoading(true);
    setErrorNotification(false);
    AuthController.forgotPassword(onSuccess, onError, values);
  };

  const validateFormHasEmptyFields = (values: IForgotForm) => {
    const { email: formEmail } = values;
    if (!_.isEmpty(formEmail)) return false;
    return true;
  };

  const renderForgotSuccess = () => (
    <>
      <FormDescription
        className="c-form-description--big"
        text="Remember to check your inbox or spam folder."
      />
      <Link to="/" className="text-decoration-none">
        <div className="d-flex justify-content-center">
          <Button
            size="big"
            typeStyle="block"
            value="Back to Login"
            icon={{
              icon: "arrow_back_ios",
              size: 12,
              position: "left",
            }}
          />
        </div>
      </Link>
      <FormTextLink
        text="Didn't receive the link?"
        linkText="Resend"
        onClick={() => onSubmit({ email })}
      />
    </>
  );
  return (
    <FormView
      renderHeader={
        <FormHeader
          title=""
          subtitle={isSuccess ? "Check your e-mail" : "Forgot Password?"}
        />
      }
      renderFooter={<FormLinks hideLink />}
    >
      <FormCaption
        content={
          isSuccess ? (
            <>
              If the email <strong>{email}</strong> is in our data base you will
              receive a link to reset your password.
            </>
          ) : (
            `Dont worry! Write the e-mail associated to the account and we will send you a link to reset your password: `
          )
        }
      />
      {isSuccess ? (
        renderForgotSuccess()
      ) : (
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={ForgotSchema}
        >
          {({ values, errors, handleBlur }) => (
            <FormikForm
              className="c-login__form-container d-flex flex-column flex-grow-1"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <TextField
                name="email"
                label="E-mail"
                error={errors.email}
                type="email"
                placeholder="Enter E-mail"
                handleBlur={handleBlur}
                errorHandler={errorNotification}
                renderError={
                  errorNotification ? (
                    <FormError error="Invalid Credentials" />
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  )
                }
                iconName="email"
              />
              <div className="bottom-button-container d-flex flex-column justify-content-center align-items-center">
                <Button
                  type="submit"
                  isLoading={isLoading}
                  size="big"
                  typeStyle="block"
                  value="Submit"
                  disabled={
                    validateFormHasEmptyFields(values) || !_.isEmpty(errors)
                  }
                />
                <div className="mt-3">
                  <ButtonGoBack />
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </FormView>
  );
};

export default ForgotPassword;
